import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
	return (
		<div>
			<h1>Страница не найдена</h1>
			<Link to="/" className="m-5 fs-5">
				На главную
			</Link>
		</div>
	);
};

export default ErrorPage;
