import React from "react";
import { Dropdown } from "react-bootstrap";
import classes from "./BrandDropdown.module.css";

const BrandDropdownMenu = ({ dropdownMenu, className }) => {
	return (
		<Dropdown.Menu className={`${className || ""} ${classes.dropdownMenu}`}>
			{dropdownMenu}
		</Dropdown.Menu>
	);
};

export default BrandDropdownMenu;
