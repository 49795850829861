import { useLocation, useNavigate, useParams } from "react-router-dom";

const useRouter = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const params = useParams();

	return { location, navigate, params };
};

export default useRouter;
