export const saveToLocalStorage = (key, value) => {
	try {
		localStorage.setItem(key, JSON.stringify(value));
	} catch (error) {
		console.error("Error saving to localStorage", error);
	}
};

export const getFromLocalStorage = (key) => {
	try {
		const storedValue = localStorage.getItem(key);
		return storedValue ? JSON.parse(storedValue) : null;
	} catch (error) {
		console.error("Error getting data from localStorage", error);
	}
};
