import React from "react";
import { Link } from "react-router-dom";
import classes from "./BrandButton.module.css";

const IconAndChildrenRender = (icon, children) => {
	return (
		<>
			{icon && <span className={classes.icon}>{icon}</span>}
			<span>{children}</span>
		</>
	);
};

const BrandButton = ({ children, type, onClick, disabled, className, icon, to, target }) => {
	return (
		<>
			{to ? (
				<Link to={to} className={`btn-brand ${className}`} target={target}>
					{IconAndChildrenRender(icon, children)}
				</Link>
			) : (
				<button
					type={type}
					disabled={disabled}
					onClick={onClick}
					className={`btn-brand ${className}`}
				>
					{IconAndChildrenRender(icon, children)}
				</button>
			)}
		</>
	);
};

export default BrandButton;
