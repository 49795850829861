import React from "react";
import classes from "./InfoBlock.module.css";

const InfoBlock = ({ children, className, type = "non-outline" }) => {
	return (
		<div
			className={`${className || ""} ${classes.infoBlock} ${
				type === "outline" ? classes.outline : ""
			}`}
		>
			{children}
		</div>
	);
};

export default InfoBlock;
