import { create } from "zustand";
import { DEFAULT_THEME } from "@const/themes";

const useThemeStore = create((set, get) => ({
	currentTheme: false,
	getTheme: () => localStorage.getItem("brandTheme") || DEFAULT_THEME.code,
	setTheme: (code) => {
		set({
			currentTheme: code,
		});
		localStorage.setItem("brandTheme", code);
	},
	changeTheme: (code) => {
		get().setTheme(code);
		let html = document.querySelector("html");
		html.setAttribute("data-theme", code);
	},
}));
export default useThemeStore;
