import { useEffect } from "react";
import { socket } from "@/socket";
import useAuthStore from "@store/AuthStore";

const ConnectWebSocket = () => {
	const { setIsWebSocketConnected } = useAuthStore();
	function onConnect() {
		setIsWebSocketConnected(true);
		console.log("web-socket connected");
	}

	function onDisconnect() {
		setIsWebSocketConnected(false);
	}
	useEffect(() => {
		socket.on("connect", onConnect);
		socket.on("disconnect", onDisconnect);

		return () => {
			socket.off("connect", onConnect);
			socket.off("disconnect", onDisconnect);
		};
	}, [setIsWebSocketConnected]);
};

export default ConnectWebSocket;
