import { create } from "zustand";
import useRequestStore from "./RequestStore";
import useSystemStore from "./SystemStore";
import useOrderStore from "./OrderStore";
import { encryptRSA } from "@helpers/crypto";
const useProfileStore = create((set, get) => ({
	profileInfo: null,
	editedProfileData: {
		password: "",
		phone: "",
		surname: "",
		second_name: "",
		social_network_accounts: [{ social_network_id: "", account_id: "" }],
		bank_account: {
			bank_title: "",
			bank_bik: "",
			bank_ks: "",
			account: "",
		},
	},
	setEditedProfileInfo: (keyPath, value) => {
		set((state) => {
			const keys = keyPath.split(".");
			const lastKey = keys.pop();

			const nestedUpdate = (obj) => {
				if (keys.length === 0) {
					return { ...obj, [lastKey]: value };
				}

				const key = keys.shift();
				return {
					...obj,
					[key]: nestedUpdate(obj[key] || {}),
				};
			};

			return {
				profileInfo: nestedUpdate(state.profileInfo),
			};
		});
	},
	setProfileInfo: (data) => {
		set({
			profileInfo: data,
		});
	},
	getProfileInfo: () => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("users:profile", {
			successCb: (responseData) => {
				get().setProfileInfo(responseData.item);
			},
			errorCb: (error) => {
				console.error("Ошибка получения профиля:", error);
			},
		});
	},
	editProfileInfo: ({ data, successCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("users:profile_update", {
			requestData: data,
			successCb: (responseData) => {
				get().setProfileInfo(responseData.item);
				successCb && successCb();
				console.log("Успешное редактирование данных профиля");
			},
			errorCb: (error) => {
				alert(error.title);
				console.error("Ошибка редактирования профиля:", error);
			},
		});
	},
	getSocialsAccount: (activeSocials) => {
		return get()
			.profileInfo.social_network_accounts.map((account) => {
				const social = activeSocials.find((social) => social.code === account.social_network.code);
				return social
					? { social_network_id: social.id, account_id: account.account_id.trim() }
					: null;
			})
			.filter(Boolean);
	},

	formatProfileData: (socialsData) => {
		const trimString = (str) => (str ? str.trim() : null);

		const systemSettings = useSystemStore.getState().systemSettings;
		const profileInfo = get().profileInfo;
		const bankAccount = profileInfo.bank_account || {};
		let account_or_account_mask = bankAccount.account || bankAccount.account_mask;
		const encryptedAccount = account_or_account_mask
			? encryptRSA(account_or_account_mask.trim(), systemSettings.public_key)
			: "";
		console.log(account_or_account_mask.trim());

		return {
			password: null,
			phone: trimString(profileInfo.phone),
			surname: trimString(profileInfo.surname),
			second_name: trimString(profileInfo.second_name),
			name: trimString(profileInfo.name),
			social_network_accounts: socialsData,
			bank_account: {
				bank_title: trimString(bankAccount.bank_title),
				bank_bik: trimString(bankAccount.bank_bik),
				bank_ks: trimString(bankAccount.bank_ks),
				account: encryptedAccount,
			},
		};
	},

	saveProfile: (navigate) => {
		const getSocialNetworkList = useOrderStore.getState().getSocialNetworkList;

		getSocialNetworkList({
			page: 1,
			limit: 100,
			successCb: (data) => {
				const socialsData = get().getSocialsAccount(data.items);
				const formattedData = get().formatProfileData(socialsData);
				get().editProfileInfo({ data: formattedData, successCb: () => navigate(-1) });
			},
		});
	},
}));

export default useProfileStore;
