import React from "react";
import { Dropdown } from "react-bootstrap";
import BrandDropdownMenu from "./BrandDropdownMenu";

const BrandDropdown = ({ triggerComponent, dropdownMenu, dropdownMenuClassName }) => {
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		// eslint-disable-next-line jsx-a11y/anchor-is-valid
		<a
			href=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			style={{ textDecoration: "none" }}
		>
			{children}
		</a>
	));

	return (
		<Dropdown>
			<Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
				{triggerComponent}
			</Dropdown.Toggle>

			<BrandDropdownMenu dropdownMenu={dropdownMenu} className={dropdownMenuClassName} />
		</Dropdown>
	);
};

export default BrandDropdown;
