import React, { useEffect, useState } from "react";
import PageTitle from "@ui/PageTitle/PageTitle";
import ContractorsTable from "@components/ContractorsTable/ContractorsTable";
import useUserStore from "@store/UserStore";
import BrandButton from "@components/ui/Button/BrandButton";
import classes from "./ContractorsPage.module.css";
import useTransactionStore from "@store/TransactionStore";
import useAuthStore from "@store/AuthStore";

const ContractorsPage = () => {
	const { getUserList, userList, setUserList } = useUserStore();
	const { isOperationalDirector } = useAuthStore();
	const { balanceDebiting } = useTransactionStore();
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const limit = 10;

	const showMore = () => {
		if (userList.length < totalCount) {
			setCurrentPage((prev) => prev + 1);
		}
	};

	const sumBalance = () => {
		let sum = 0;
		selectedUsers.forEach((user) => {
			if (user.wallet?.balance) {
				sum += user?.wallet?.balance;
			}
		});
		return sum ? `${sum} ₽` : "";
	};

	const setDefaultParams = () => {
		setUserList([]);
		setSelectedUsers([]);
		setTotalCount(0);
		setCurrentPage(1);
	};

	const successPayment = () => {
		setDefaultParams();
		getUserList({ page: 1, limit, successCb: (data) => setTotalCount(data.total) });
	};

	const payContactors = () => {
		let user_ids = selectedUsers.map((user) => user.id);
		balanceDebiting({ user_ids, successCb: successPayment });
	};

	const setMoreUsers = (newUsers) => {
		setTotalCount(newUsers.total);

		setUserList([...userList, ...newUsers.items]);
	};

	useEffect(() => {
		getUserList({ page: currentPage, limit, successCb: setMoreUsers });
	}, [currentPage]);

	useEffect(() => {
		return () => setDefaultParams();
	}, []);

	return (
		<>
			<div className={classes.pageHeader}>
				<PageTitle title="Исполнители" className={classes.title} />

				{isOperationalDirector() && sumBalance() && (
					<BrandButton
						className={`btn-brand-non-outline ${classes.payButton}`}
						onClick={payContactors}
					>
						{selectedUsers.length === userList.length && !sumBalance()
							? `Оплачено`
							: `Оплатить ${sumBalance()}`}
					</BrandButton>
				)}
			</div>
			<ContractorsTable
				userList={userList}
				selectedUsers={selectedUsers}
				setSelectedUsers={setSelectedUsers}
			/>
			<div className={classes.container}>
				{totalCount > userList.length && (
					<BrandButton className={`btn-brand-outline ${classes.moreButton}`} onClick={showMore}>
						Показать еще
					</BrandButton>
				)}
			</div>
		</>
	);
};

export default ContractorsPage;
