import BrandButton from "@components/ui/Button/BrandButton";
import useHeaderStore from "@store/HeaderStore";
import React from "react";
import useAuthStore from "@store/AuthStore";
import useRouter from "@hooks/useRouter";

const ProfilePageHeader = () => {
	const { isHeaderFixed } = useHeaderStore();
	const { logout } = useAuthStore();
	const { navigate } = useRouter();
	return (
		<>
			{isHeaderFixed && <p className="text-md-medium">Профиль</p>}
			<div className="ms-auto d-flex gap-4">
				<BrandButton
					onClick={() => {
						navigate("/profile/edit");
					}}
					className="btn-brand-outline mobile-hide"
				>
					Редактировать
				</BrandButton>
				<BrandButton className="btn-brand-outline mobile-hide" onClick={logout}>
					Выйти
				</BrandButton>
			</div>
		</>
	);
};

export default ProfilePageHeader;
