import React from "react";
import InfoBlock from "@components/ui/InfoBlock/InfoBlock";
import BrandInput from "@components/ui/Input/BrandInput";
import classes from "./UpdateUserInfoForm.module.css";
import useProfileStore from "@store/ProfileStore";

const UpdateUserInfoForm = () => {
	const { profileInfo, setEditedProfileInfo } = useProfileStore();

	const onlyNumbers = (event) => {
		if (!/[0-9]/.test(event.key)) {
			event.preventDefault();
		}
	};

	const hasSocial = (code) => {
		if (!profileInfo) return;

		const instagramAccount = profileInfo.social_network_accounts.find(
			(social) => social.social_network.code === code
		);

		return instagramAccount ? instagramAccount.account_id : null;
	};

	const setSocialAccount = (code, accountId) => {
		const socialAccounts = [...profileInfo.social_network_accounts];
		const accountIndex = socialAccounts.findIndex((social) => social.social_network.code === code);

		if (accountIndex !== -1) {
			socialAccounts[accountIndex].account_id = accountId;
		} else {
			socialAccounts.push({
				social_network: { code },
				account_id: accountId,
			});
		}

		setEditedProfileInfo("social_network_accounts", socialAccounts);
	};

	const renderInputField = ({ title, value, onChange, onKeyPress = null, disabled }) => (
		<BrandInput
			key={title}
			placeholder={title}
			label={title}
			className={classes.input}
			value={value}
			onChange={onChange}
			onKeyPress={onKeyPress}
			disabled={disabled}
		/>
	);

	const renderSection = (title, fields) => (
		<InfoBlock type="outline" className={`col-12 ${classes.infoBlock}`} key={title}>
			<p className={classes.title}>{title}</p>
			<div className={classes.container}>{fields.map(renderInputField)}</div>
		</InfoBlock>
	);

	return (
		<form className={`row ${classes.form}`}>
			{renderSection("Общая информация", [
				{
					title: "Имя",
					value: profileInfo?.name || "",
					onChange: (v) => setEditedProfileInfo("name", v),
				},
				{
					title: "Отчество",
					value: profileInfo?.second_name || "",
					onChange: (v) => setEditedProfileInfo("second_name", v),
				},
				{
					title: "Фамилия",
					value: profileInfo?.surname || "",
					onChange: (v) => setEditedProfileInfo("surname", v),
				},
			])}
			{renderSection("Контакты", [
				{
					title: "Телефон",
					value: profileInfo?.phone || "",
					onChange: (v) => setEditedProfileInfo("phone", v),
				},
				{
					title: "Почта",
					value: profileInfo?.email || "",
					onChange: (v) => setEditedProfileInfo("email", v),
					disabled: true,
				},
				{
					title: "Инстаграм",
					value: hasSocial("instagram") || "",
					onChange: (v) => setSocialAccount("instagram", v),
				},
				{
					title: "Телеграм",
					value: hasSocial("telegram") || "",
					onChange: (v) => setSocialAccount("telegram", v),
				},
			])}
			{renderSection("Реквизиты", [
				{
					title: "Наименование банка",
					value: profileInfo?.bank_account?.bank_title || "",
					onChange: (v) => setEditedProfileInfo("bank_account.bank_title", v),
				},
				{
					title: "Расчетный счет",
					value: profileInfo?.bank_account?.account_mask || "",
					onChange: (v) => setEditedProfileInfo("bank_account.account_mask", v),
					onKeyPress: onlyNumbers,
				},
				{
					title: "Корреспондентский счёт",
					value: profileInfo?.bank_account?.bank_ks || "",
					onChange: (v) => setEditedProfileInfo("bank_account.bank_ks", v),
					onKeyPress: onlyNumbers,
				},
				{
					title: "БИК",
					value: profileInfo?.bank_account?.bank_bik || "",
					onChange: (v) => setEditedProfileInfo("bank_account.bank_bik", v),
					onKeyPress: onlyNumbers,
				},
			])}
		</form>
	);
};

export default UpdateUserInfoForm;
