import React from "react";
import classes from "./Screenshots.module.css";
import useModalStore from "@store/ModalStore";
const Screenshots = ({ displayedTasks }) => {
	const { showModal } = useModalStore();

	return (
		<div className={classes.wrapper}>
			{displayedTasks.length > 0 &&
				displayedTasks.map((task, index) => (
					<div
						key={index}
						className={classes.container}
						style={{
							zIndex: index,
							transform: `translateX(-${index * 20}px) translateY(-${index * 20}px)`,
							top: 0,
						}}
					>
						{task.completed_screenshot && (
							<img
								src={`${process.env.REACT_APP_BACKEND_URL}${task.completed_screenshot}`}
								alt="screenshot"
								className={classes.screenshot}
								onClick={() =>
									showModal(
										<img
											src={`${process.env.REACT_APP_BACKEND_URL}${task.completed_screenshot}`}
											alt="screenshot"
											className={classes.screenshot}
										/>,
										"fullScreenImage"
									)
								}
							/>
						)}
					</div>
				))}
		</div>
	);
};

export default Screenshots;
