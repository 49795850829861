import BrandButton from "@components/ui/Button/BrandButton";
import useRouter from "@hooks/useRouter";
import useHeaderStore from "@store/HeaderStore";
import useProfileStore from "@store/ProfileStore";
import BackButton from "@ui/BackButton/BackButton";
import React from "react";

const ProfilePageEditHeader = () => {
	const { isHeaderFixed } = useHeaderStore();
	const { saveProfile } = useProfileStore();
	const { navigate } = useRouter();

	return (
		<>
			<BackButton className="me-3 mobile-hide" />
			{isHeaderFixed && <p className="text-md-medium">Редактирование профиля</p>}
			<BrandButton
				className="btn-brand-xl mobile-hide ms-auto"
				onClick={() => saveProfile(navigate)}
			>
				Сохранить
			</BrandButton>
		</>
	);
};

export default ProfilePageEditHeader;
