import React, { useState } from "react";
import classes from "./CancelTaskComment.module.css";
import BrandButton from "@components/ui/Button/BrandButton";
import BrandTextArea from "@components/ui/TextArea/BrandTextArea";
import { ORDER_STATUS } from "@const/order";
import useTaskStore from "@store/TaskStore";

const CancelTaskComment = ({ setShowCancelledBlock, removeCheckedTask }) => {
	const { changeTaskStatus, taskListForCheck } = useTaskStore();
	const [comment, setComment] = useState("");

	const cancelTask = () => {
		const idList = taskListForCheck.map((e) => e.id);
		if (!comment) {
			alert("Добавьте комментарий");
			return;
		}
		console.log({
			id: idList,
			status: ORDER_STATUS.REJECTED,
			verified_comment: comment,
		});

		changeTaskStatus({
			id: idList,
			status: ORDER_STATUS.REJECTED,
			verified_comment: comment,
			successCb: () => {
				removeCheckedTask();
				setShowCancelledBlock(false);
			},
		});
	};
	return (
		<div className={classes.wrapper}>
			<h2>Что не так?</h2>
			<BrandTextArea value={comment} onChange={(e) => setComment(e)} />
			<BrandButton className="btn-brand-outline" onClick={cancelTask}>
				Отправить
			</BrandButton>
		</div>
	);
};

export default CancelTaskComment;
