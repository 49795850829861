import { ReactComponent as Plus } from "@assets/icons/button/plus.svg";
import InviteContractor from "@components/InviteContractor/InviteContractor";
import BrandButton from "@components/ui/Button/BrandButton";
import useHeaderStore from "@store/HeaderStore";
import useModalStore from "@store/ModalStore";
import React from "react";

const ContractorPageHeader = () => {
	const { isHeaderFixed } = useHeaderStore();
	const { showModal, setModalMessage } = useModalStore();
	
	const inviteContractor = () => {
		showModal(true);
		setModalMessage(<InviteContractor />);
	};

	return (
		<>
			{isHeaderFixed && <p>Исполнители</p>}
			<BrandButton
				onClick={inviteContractor}
				className="btn-brand-xl mobile-hide ms-auto"
				icon={<Plus />}
			>
				Добавить исполнителя
			</BrandButton>
		</>
	);
};

export default ContractorPageHeader;
