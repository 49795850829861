import MobileBottomBar from "@components/MobileBottomBar/MobileBottomBar";
import BrandButton from "@components/ui/Button/BrandButton";
import useProfileStore from "@store/ProfileStore";
import useRouter from "@hooks/useRouter";
import React from "react";

const ProfilePageEditMobileBottomBar = () => {
	const { saveProfile } = useProfileStore();
	const { navigate } = useRouter();
	return (
		<MobileBottomBar>
			<BrandButton className="btn-brand-xl w-100" onClick={() => saveProfile(navigate)}>
				Сохранить
			</BrandButton>
		</MobileBottomBar>
	);
};

export default ProfilePageEditMobileBottomBar;
