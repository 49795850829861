import Cookies from "js-cookie";
import { TOKEN } from "@const/token";

const IsValidToken = () => {
	const accessToken = Cookies.get(TOKEN.BRAND_ACCESS_TOKEN);
	const refreshToken = Cookies.get(TOKEN.BRAND_REFRESH_TOKEN);

	if (accessToken) {
		return accessToken;
	}

	if (refreshToken) {
		return refreshToken;
	}

	return null;
};

export default IsValidToken;
