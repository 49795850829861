import CloseButton from "@components/ui/CloseButton/CloseButton";

import useMobileMenuStore from "@store/MobileMenuStore";
import React, { useEffect, useState } from "react";
import classes from "./MobileMenu.module.css";
import MobileMenuContent from "./MobileMenuContent/MobileMenuContent";
import MobileMenuFooter from "./MobileMenuFooter/MobileMenuFooter";

const MobileMenu = () => {
	const { setIsMobileMenuShow } = useMobileMenuStore();
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		setIsVisible(true);
	}, []);

	const closeMenu = () => {
		setIsVisible(false);
		setTimeout(() => setIsMobileMenuShow(false), 1000);
	};

	const handleOverlayClick = () => {
		closeMenu();
	};

	const handleWrapperClick = (event) => {
		event.stopPropagation();
	};

	return (
		<div
			className={`${classes.overlay} ${!isVisible ? classes.hidden : ""}`}
			onClick={handleOverlayClick}
		>
			<div
				className={`${classes.wrapper} ${isVisible ? classes.show : ""}`}
				onClick={handleWrapperClick}
			>
				<div className={classes.header}>
					<CloseButton onClick={closeMenu} />
				</div>
				<nav className={classes.content}>
					<MobileMenuContent closeMenu={closeMenu} />
				</nav>
				<div className={classes.footer}>
					<MobileMenuFooter closeMenu={closeMenu} />
				</div>
			</div>
		</div>
	);
};

export default MobileMenu;
