import { useEffect, useState } from "react";

const useTaskLoader = ({ initialTaskStatus, fetchTasks, itemsPerPage = 30, filterField, id }) => {
	const [taskStatus, setTaskStatus] = useState(initialTaskStatus);
	const [currentPage, setCurrentPage] = useState(1);
	const [fetching, setFetching] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [loadedTasks, setLoadedTasks] = useState([]);

	useEffect(() => {
		if (!initialTaskStatus) {
			return;
		}

		setTaskStatus(initialTaskStatus);
	}, [initialTaskStatus]);

	const handleTabSelect = (code) => {
		setFetching(true);
		setLoadedTasks([]);
		setCurrentPage(1);
		setTaskStatus(code);
	};

	const clearTasks = () => {
		setLoadedTasks([]);
		setFetching(false);
		setCurrentPage(1);
		setTotalCount(0);
	};

	const setMoreTasks = (newTasks) => {
		setTotalCount(newTasks.total);
		setCurrentPage((prev) => prev + 1);
		setLoadedTasks((prevTasks) => [...prevTasks, ...newTasks.items]);
	};

	const loadTasks = ({ page = currentPage, id } = {}) => {
		const useFilter =
			taskStatus === "all"
				? {}
				: filterField === "state_code"
				? {
						state_code: [taskStatus],
				  }
				: filterField === "state_id"
				? {
						state_id: [taskStatus],
				  }
				: { code: taskStatus };

		const fetchParams = {
			page,
			limit: itemsPerPage,
			filters: useFilter,
			successCb: setMoreTasks,
			finallyCb: () => setFetching(false),
		};

		if (id) {
			fetchParams.id = id;
		}

		fetchTasks(fetchParams);
	};

	useEffect(() => {
		if (fetching && taskStatus) {
			id ? loadTasks({ id }) : loadTasks();
		}
	}, [fetching, taskStatus]);

	useEffect(() => {
		setLoadedTasks([]);
		setCurrentPage(1);
		setFetching(true);
	}, [taskStatus]);

	return {
		taskStatus,
		currentPage,
		fetching,
		totalCount,
		loadedTasks,
		handleTabSelect,
		loadTasks,
		setFetching,
		setLoadedTasks,
		clearTasks,
	};
};

export default useTaskLoader;
