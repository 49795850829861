import { create } from "zustand";

const useHeaderStore = create((set, get) => ({
	isHeaderFixed: null,
	setHeaderFixed: (value) => {
		set({ isHeaderFixed: value });
	},
}));

export default useHeaderStore;
