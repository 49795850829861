import React from "react";
import Balance from "@components/Balance/Balance";
import classes from "./TaskPageHeader.module.css";

const TaskPageHeader = () => {
	return (
		<div className={classes.wrapper}>
			<Balance />
		</div>
	);
};

export default TaskPageHeader;
