import React from "react";
import BrandInput from "@ui/Input/BrandInput";

const RestorePassword = () => {
	return (
		<>
			<BrandInput type="text" />
			<BrandInput type="text" />
		</>
	);
};

export default RestorePassword;
