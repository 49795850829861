import React from "react";
import classes from "./TaskItem.module.css";

const TaskItem = ({ title, count, isActive, onClick }) => {
	return (
		<div
			className={`text-xs-medium ${classes.task} ${isActive ? classes.active : ""}`}
			onClick={onClick}
		>
			{title} {count}
		</div>
	);
};

export default TaskItem;
