import BrandButton from "@components/ui/Button/BrandButton";
import BrandInput from "@components/ui/Input/BrandInput";
import { ORDER_STATUS } from "@const/order";
import useLoaderStore from "@store/LoaderStore";
import useTaskStore from "@store/TaskStore";
import React, { useState } from "react";
import classes from "./TaskBottomBar.module.css";
import useModalStore from "@store/ModalStore";
import useRouter from "@hooks/useRouter";

const TaskBottomBar = ({ task, loadTasks, clearTasks, takeTaskHandler, setStep }) => {
	const { isAppLoading } = useLoaderStore();
	const { changeTaskStatus, getMyTasks } = useTaskStore();
	const { hideModal } = useModalStore();
	const [showCancelInput, setShowCancelInput] = useState(false);
	const [comment, setComment] = useState("");
	const { navigate } = useRouter();
	const handleEndClick = () => {
		setStep((prev) => prev + 1);
	};

	const navigateToTaskList = () => {
		navigate("/tasks");
		hideModal();
	};

	const changeTaskStatusHandler = () => {
		if (!comment) {
			setShowCancelInput(true);
			return;
		}
		changeTaskStatus({
			id: task.id,
			status: ORDER_STATUS.CANCELLED,
			comment,
			successCb: () => {
				clearTasks && clearTasks();
				getMyTasks({ page: 1, limit: 100 });
				loadTasks && loadTasks({ page: 1 });
				hideModal();
			},
		});
	};

	const renderCancelInput = () => {
		if (showCancelInput) {
			return (
				<BrandInput
					placeholder="Причина отмены"
					value={comment}
					onChange={(e) => setComment(e)}
					autoComplete="false"
				/>
			);
		}
		return null;
	};

	const renderActionButton = () => {
		if (!task.order_activity_id) {
			return (
				<BrandButton
					className={`${classes.button} btn-brand-xl`}
					onClick={() => takeTaskHandler(task.id)}
					disabled={isAppLoading}
				>
					{task.order.state.code === ORDER_STATUS.PENDING ? "Забронировать" : "В работу"}
				</BrandButton>
			);
		}

		if (task.state.code === ORDER_STATUS.APPROVED) {
			return (
				<div className={classes.bottomBarContainer}>
					{renderCancelInput()}
					<div className={classes.buttonContainer}>
						<BrandButton
							className={`${classes.cancel} btn-brand-non-outline`}
							onClick={changeTaskStatusHandler}
							disabled={isAppLoading}
						>
							Отменить
						</BrandButton>
						<BrandButton
							className={`${classes.end} btn-brand-xl`}
							onClick={handleEndClick}
							disabled={isAppLoading}
						>
							Завершить
						</BrandButton>
					</div>
				</div>
			);
		}

		if (task.state.code === ORDER_STATUS.PENDING) {
			return (
				<div className={classes.buttonContainer}>
					{task.order.state.code === ORDER_STATUS.PENDING ? (
						<BrandButton className={`${classes.cancel} btn-brand-xl w-100`} disabled={isAppLoading}>
							Начать
						</BrandButton>
					) : (
						<BrandButton
							className={`${classes.cancel} btn-brand-non-outline w-100`}
							onClick={changeTaskStatusHandler}
							disabled={isAppLoading}
						>
							Отменить бронь
						</BrandButton>
					)}
				</div>
			);
		}

		return (
			<div className={classes.buttonContainer}>
				<BrandButton
					className={`${classes.default} btn-brand-xl w-100`}
					disabled={isAppLoading}
					onClick={navigateToTaskList}
				>
					К заданиям
				</BrandButton>
			</div>
		);
	};

	return <>{renderActionButton()}</>;
};

export default TaskBottomBar;
