import { create } from "zustand";

const useMobileMenuStore = create((set, get) => ({
	isMobileMenuShow: null,
	setIsMobileMenuShow: (value) => {
		set({ isMobileMenuShow: value });
	},
}));

export default useMobileMenuStore;
