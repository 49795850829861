import React from "react";
import classes from "./Agreement.module.css";
const Agreement = () => {
	return (
		<p className={classes.agreement}>
			Продолжая, вы принимаете согласие на{" "}
			<a href="#" target="_blank">
				обработку персональных данных
			</a>
			,{" "}
			<a href="#" target="_blank">
				политику конфиденциальности
			</a>{" "}
			и{" "}
			<a href="#" target="_blank">
				договор публичной оферты
			</a>
		</p>
	);
};

export default Agreement;
