import React, { useEffect } from "react";
import TaskItem from "../TaskItem/TaskItem";
import useOrderStore from "@store/OrderStore";
import useModuleStore from "@store/ModuleStore";
import classes from "./TaskList.module.css";

const TaskList = ({ valid, social_network_id, isEdit }) => {
	const {
		socialNetworkActivitiesList,
		getSocialNetworkActivitiesList,
		activeTasks,
		setActiveTasks,
		getTaskId,
		getTaskActivityId,
	} = useOrderStore();
	const { getSocialNetworkActivitiesModule, getActiveStateForModule } = useModuleStore();

	useEffect(() => {
		if (!social_network_id) return;

		let socialNetworkModule = getSocialNetworkActivitiesModule();
		let activeSocialNetworksActivitiesId = getActiveStateForModule(socialNetworkModule);

		getSocialNetworkActivitiesList({
			page: 1,
			limit: 100,
			filters: {
				social_network_id: [social_network_id],
				state_id: [activeSocialNetworksActivitiesId],
			},
		});
	}, [social_network_id]);

	const handleTaskClick = (activity) => {
		const isActive = activeTasks.some((task) => {
			const taskId = getTaskId(task);
			const taskActivityId = getTaskActivityId(task);

			return taskId === activity.id || taskActivityId === activity.activity_id;
		});

		setActiveTasks(
			isActive
				? activeTasks.filter((task) => {
						return isEdit
							? getTaskActivityId(task) !== activity.activity_id
							: getTaskId(task) !== activity.id && task.activity_id !== activity.activity_id;
				  })
				: [...activeTasks, activity]
		);
	};

	return (
		<>
			<p className={`${!valid ? classes.errorTitle : ""} text-sm-medium`}>Задания</p>

			<div className={classes.tasksContainer}>
				{socialNetworkActivitiesList?.length > 0 &&
					Array.from(new Set(socialNetworkActivitiesList.map((item) => item.activity_id)))
						.map((id) => socialNetworkActivitiesList.find((item) => item.id === id))
						.map((activity) => {
							const isActive = activeTasks.some((task) => {
								if (isEdit) {
									return (
										getTaskActivityId(task) === (activity.activity_id || task.id === activity.id)
									);
								}
								return task.id === activity.id || task.activity_id === activity.activity_id;
							});
							return (
								<TaskItem
									key={activity.id}
									title={activity.activity.title}
									onClick={() => handleTaskClick(activity)}
									isActive={isActive}
								/>
							);
						})}
			</div>
		</>
	);
};

export default TaskList;
