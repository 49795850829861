import Cookies from "js-cookie";
import { create } from "zustand";
import { socket } from "@/socket";
import useAuthStore from "./AuthStore";
import useLoaderStore from "./LoaderStore";
import { LOGIN_WITH_TOKEN, TOKEN } from "@const/token";

const useRequestStore = create((set, get) => ({
	handleRequest: (eventName, options = {}) => {
		const { requestData = null, successCb, errorCb, finallyCb, needLoader = true } = options;
		const { login, logout } = useAuthStore.getState();
		const { setIsLoading } = useLoaderStore.getState();

		needLoader && setIsLoading(true);

		if (!socket.connected) {
			console.error("Ошибка: WebSocket не подключен");
			needLoader && setIsLoading(false);
			return;
		}

		socket.off(eventName);
		socket.emit(eventName, requestData || null);

		socket.on(eventName, async (data) => {
			if (data.status === "error") {
				console.error(data.error.code);
				let code = data.error.code;

				if (code === "jwt_token_is_expired") {
					const storedRefreshToken = Cookies.get(TOKEN.BRAND_REFRESH_TOKEN);
					if (storedRefreshToken) {
						login(null, storedRefreshToken, LOGIN_WITH_TOKEN);

						setTimeout(() => {
							get().handleRequest(eventName, options);
						}, 1000);
					} else {
						logout();
					}
				} else if (code === "signin_has_not_been_completed") {
					window.location.href = "/signin";
				} else if (errorCb) {
					errorCb(data.error);
				}

				needLoader && setIsLoading(false);
			} else if (successCb) {
				successCb(data);
				needLoader && setIsLoading(false);
			}

			if (finallyCb) {
				finallyCb();
				needLoader && setIsLoading(false);
			}
		});
	},
}));

export default useRequestStore;
