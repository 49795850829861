import forge from "node-forge";

export const encryptRSA = (data, publicKeyPem) => {
	try {
		const decodePublicKey = forge.util.decode64(publicKeyPem);

		const publicKey = forge.pki.publicKeyFromPem(decodePublicKey);
		const bytes = forge.util.decodeUtf8(data);

		const encrypted = publicKey.encrypt(bytes, "RSA-OAEP", {
			md: forge.md.sha256.create(),
			mgf: forge.mgf.mgf1.create(forge.md.sha256.create()),
		});

		const base64Encrypted = forge.util.encode64(encrypted);

		return base64Encrypted;
	} catch (error) {
		console.error("Ошибка шифрования:", error);
		throw error;
	}
};
