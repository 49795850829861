import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

const months = [
	"января",
	"февраля",
	"марта",
	"апреля",
	"мая",
	"июня",
	"июля",
	"августа",
	"сентября",
	"октября",
	"ноября",
	"декабря",
];

export const convertISO8601Duration = (isoString, includeSuffixes = true) => {
	let totalHours = 0;

	if (isoString.includes("D")) {
		const daysMatch = isoString.match(/P(\d+)D/);
		if (daysMatch) {
			const days = parseInt(daysMatch[1], 10);
			totalHours += days * 24;
		}
	}

	const durationObj = dayjs.duration(isoString);
	const hours = durationObj.hours();
	const minutes = durationObj.minutes();

	totalHours += hours;

	let result = "";
	if (totalHours > 0) {
		result += includeSuffixes ? `${totalHours} ч` : `${totalHours}`;
	}
	if (minutes > 0) {
		result += includeSuffixes ? ` ${minutes} м` : ` ${minutes}`;
	}

	return result.trim();
};

export const convertToISO8601Duration = (hours) => {
	const durationObj = dayjs.duration({
		hours: hours,
	});

	return durationObj.toISOString();
};
export const calculateDelay = (orderDate) => {
	const orderTime = new Date(orderDate);
	const currentTime = new Date();

	if (currentTime > orderTime) {
		const delayInMilliseconds = currentTime - orderTime;

		const delayInMinutes = Math.floor(delayInMilliseconds / (1000 * 60));
		const delayInHours = Math.floor(delayInMinutes / 60);
		const delayInDays = Math.floor(delayInHours / 24);

		return {
			days: delayInDays,
			hours: delayInHours % 24,
			minutes: delayInMinutes % 60,
		};
	}
	return null;
};
export const convertDatetime = (dateStr) => {
	if (dateStr === null) return null;
	const date = new Date(dateStr);

	const day = date.getDate();
	const month = months[date.getMonth()];
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");

	return `${day} ${month} ${hours}:${minutes}`;
};

export const formatDate = ({ dateString, withTime = false }) => {
	if (!dateString) return "-";

	const date = new Date(dateString);

	const dateOptions = { day: "2-digit", month: "2-digit", year: "numeric" };

	const timeOptions = { hour: "2-digit", minute: "2-digit" };

	const formattedDate = date.toLocaleDateString("ru-RU", dateOptions);

	if (withTime) {
		const formattedTime = date.toLocaleTimeString("ru-RU", timeOptions);
		return `${formattedDate} ${formattedTime}`;
	}

	return formattedDate;
};
export const formatDateLongMonth = (date) => {
	if (!date) return null;
	return new Date(date).toLocaleDateString("ru-RU", {
		day: "numeric",
		month: "long",
		year: "numeric",
	});
};
export const calculateTimeDifference = (finishedOn) => {
	const finishedDate = new Date(finishedOn);
	const currentDateObj = new Date();

	if (currentDateObj > finishedDate) {
		return null;
	}

	const timeDifference = finishedDate - currentDateObj;

	const totalMinutes = Math.floor(timeDifference / (1000 * 60));
	const hours = Math.floor(totalMinutes / 60);
	const minutes = totalMinutes % 60;
	if (hours > 0 && minutes > 0) {
		return `${hours}ч${minutes}м`;
	} else if (hours > 0) {
		return `${hours}ч`;
	} else {
		return `${minutes}м`;
	}
};

export const formatTime = (totalSeconds) => {
	const hours = Math.floor(totalSeconds / 3600);
	const minutes = Math.floor((totalSeconds % 3600) / 60);
	const seconds = totalSeconds % 60;
	return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
		.toString()
		.padStart(2, "0")}`;
};
