export const isEmptyField = (field) => {
	const trimmedField = field.trim();
	return !trimmedField;
};

export const debounce = (func, delay) => {
	let timeout;
	return (...args) => {
		if (timeout) {
			clearTimeout(timeout);
		}
		timeout = setTimeout(() => {
			func(...args);
		}, delay);
	};
};

export function hasMatchingRole(userRole, elementRoles) {
	return elementRoles.includes(userRole);
}
