import { ReactComponent as Plus } from "@assets/icons/button/plus.svg";
import BrandButton from "@components/ui/Button/BrandButton";
import useHeaderStore from "@store/HeaderStore";
import React from "react";

const OrdersPageHeader = () => {
	const { isHeaderFixed } = useHeaderStore();
	return (
		<>
			{isHeaderFixed && <p className="text-md-medium">Все заказы</p>}
			<BrandButton to="/orders/create" className="btn-brand-xl mobile-hide ms-auto" icon={<Plus />}>
				Новый заказ
			</BrandButton>
		</>
	);
};

export default OrdersPageHeader;
