export const USER_ROLE = {
	MANAGER: "manager",
	CONTRACTOR: "contractor",
	OPERATIONAL_DIRECTOR: "operational_director",
	ADMINISTRATOR: "administrator",
};

export const ROLE_WEIGHT = [
	USER_ROLE.ADMINISTRATOR,
	USER_ROLE.OPERATIONAL_DIRECTOR,
	USER_ROLE.MANAGER,
	USER_ROLE.CONTRACTOR,
];

export const DEFAULT_ROLE = USER_ROLE.MANAGER;

export const BRAND_ACTIVE_ROLE = "brand_active_role";
