import { create } from "zustand";
import useRequestStore from "./RequestStore";

const useTransactionStore = create((set, get) => ({
	balanceDebiting: ({ user_ids, successCb, errorCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("users:balance_debiting_withdrawal", {
			requestData: { user_ids },
			successCb: (responseData) => {
				successCb && successCb(responseData);
				console.log("Успешное списание баланса");
			},
			errorCb: (error) => {
				console.error("Ошибка списания баланса:", error);
			},
		});
	},
	getBalanceDebitingReport: (entity_id, successCb, errorCb) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("user_wallet_withdrawal_sessions:download", {
			requestData: { entity_id },
			successCb: (responseData) => {
				console.log("Успешное скачивание файла");
			},
			errorCb: (error) => {
				console.error("Ошибка скачивания файла:", error);
			},
		});
	},
}));

export default useTransactionStore;
