import React from "react";
import { ReactComponent as BurgerMenuIcon } from "@assets/icons/button/burgerMenu.svg";
const BurgerMenu = ({ className, onClick }) => {
	return (
		<div className={className || ""} onClick={onClick}>
			<BurgerMenuIcon />
		</div>
	);
};

export default BurgerMenu;
