import React from "react";
import { Form } from "react-bootstrap";
import classes from "./BrandSelect.module.css";

const BrandSelect = ({ value, onChange, options, placeholder, name, className, label }) => {
	const handleChange = (e) => {
		const newValue = e.target.value;
		if (onChange) {
			onChange(newValue);
		}
	};

	return (
		<Form.Group className={`${className || ""}`}>
			{label && (
				<Form.Label htmlFor={name} className={`text-sm-medium ${classes.label}`}>
					{label}
				</Form.Label>
			)}
			<Form.Select name={name} value={value} onChange={handleChange} className={classes.select}>
				{placeholder && (
					<option value="" disabled>
						{placeholder}
					</option>
				)}
				{options?.length > 0 &&
					options.map((option) => (
						<option key={option.value} value={option.value}>
							{option.label}
						</option>
					))}
			</Form.Select>
		</Form.Group>
	);
};

export default BrandSelect;
