import TaskItem from "@components/Forms/CreateOrderForm/Tasks/TaskItem/TaskItem";
import React from "react";
import classes from "./OrderTaskCount.module.css";
import { ORDER_STATUS } from "@const/order";
const OrderTaskCount = ({ orderTaskCount, taskStatus, setTaskStatus, setTaskListForCheck }) => {
	const switchStatus = (id) => {
		if (taskStatus === id) {
			return;
		}
		setTaskListForCheck([]);
		setTaskStatus(id);
	};

	return (
		<div className={classes.taskItemContainer}>
			{orderTaskCount.length > 0 &&
				orderTaskCount.map((task) => {
					const isActive = task.id === taskStatus;
					if (task.count > 0 && task.code !== ORDER_STATUS.EXPIRED) {
						return (
							<TaskItem
								title={task.title}
								count={task.count}
								isActive={isActive}
								key={task.id}
								onClick={() => switchStatus(task.id)}
							/>
						);
					}
				})}
		</div>
	);
};

export default OrderTaskCount;
