import { hasMatchingRole } from "@helpers";
import IsValidToken from "@hooks/IsValidToken";
import useRouter from "@hooks/useRouter";
import useAuthStore from "@store/AuthStore";
import { useEffect } from "react";

const ProtectedRoute = ({ page, roles }) => {
	const { logout, isAuth, userRole, userRoles } = useAuthStore();
	const { navigate, location } = useRouter();
	const isValidToken = IsValidToken();

	const redirectToSignIn = () => navigate("/signin");
	const redirectToProfile = () => navigate("/profile");

	useEffect(() => {
		if (location.pathname === "complete_registration") {
			return;
		}

		if (!userRoles?.length || (!isValidToken && !isAuth)) {
			redirectToSignIn();
			return;
		}

		if (!isValidToken && isAuth) {
			logout();
			return;
		}

		if (!hasMatchingRole(userRole, roles)) {
			redirectToProfile();
			return;
		}
	}, [isValidToken, isAuth, userRoles, userRole, roles, logout, navigate, location]);

	return isValidToken ? page : null;
};

export default ProtectedRoute;
