import React from "react";
import classes from "./BrandAccordion.module.css";
import { Accordion } from "react-bootstrap";

const BrandAccordion = ({ content }) => {
	return (
		<Accordion className={classes.accordionWrapper}>
			{content?.length > 0 &&
				content.map((element, index) => {
					return (
						<Accordion.Item eventKey={index} key={index} className={classes.accordionItem}>
							<Accordion.Header className={`text-md-regular ${classes.accordionHeader}`}>
								{element?.title}
							</Accordion.Header>
							<Accordion.Body className={`text-md-regular ${classes.accordionBody}`}>
								{element.body}
							</Accordion.Body>
						</Accordion.Item>
					);
				})}
		</Accordion>
	);
};

export default BrandAccordion;
