import React from "react";
import { Form } from "react-bootstrap";
import classes from "./BrandTextArea.module.css";

const BrandTextArea = ({
	value,
	onChange,
	placeholder,
	name,
	className,
	label,
	rows = 4,
	valid,
}) => {
	const handleChange = (e) => {
		const newValue = e.target.value;

		onChange(newValue);
	};
	return (
		<Form.Group className={`${className || ""}`}>
			{label && (
				<Form.Label
					htmlFor={name}
					className={`text-sm-medium ${classes.label}
					${!valid ? classes.error : ""}`}
				>
					{label}
				</Form.Label>
			)}
			<div className={classes.inputContainer}>
				<Form.Control
					name={name}
					as={"textarea"}
					placeholder={placeholder}
					onChange={handleChange}
					value={value || ""}
					className={classes.textarea}
					rows={rows}
				/>
			</div>
		</Form.Group>
	);
};

export default BrandTextArea;
