import React from "react";
import classes from "./ListItem.module.css";

export const ListItem = ({ label, value = "N/A", className }) => {
	return (
		<li className={`${classes.listItem} ${className || ""}`}>
			<span className={classes.label}>{label}</span> <span className={classes.value}>{value}</span>
		</li>
	);
};

export const DateListItem = ({ label, value }) => {
	const formattedDate = value ? new Date(value).toLocaleDateString() : "N/A";
	return <ListItem label={label} value={formattedDate} />;
};
