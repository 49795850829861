import BrandButton from "@components/ui/Button/BrandButton";
import CloseButton from "@components/ui/CloseButton/CloseButton";
import { ORDER_STATUS } from "@const/order";
import useTaskStore from "@store/TaskStore";
import React, { useEffect, useRef, useState } from "react";
import classes from "./UploadImage.module.css";
import useLoaderStore from "@store/LoaderStore";

const UploadImage = ({ task, setStep, loadTasks, clearTasks }) => {
	const { uploadFile, changeTaskStatus, getMyTasks } = useTaskStore();

	const [selectedImage, setSelectedImage] = useState(null);
	const [data, setData] = useState(null);
	const fileInputRef = useRef(null);
	const { setIsLoading, isAppLoading } = useLoaderStore();

	useEffect(() => {
		return () => {
			if (selectedImage) {
				URL.revokeObjectURL(selectedImage);
			}
		};
	}, [selectedImage]);

	const handleFileChange = (event) => {
		const file_obj = event.target.files[0];
		if (file_obj) {
			const imageUrl = URL.createObjectURL(file_obj);
			setSelectedImage(imageUrl);

			const file_reader = new FileReader();

			file_reader.onload = (ev) => {
				const file_content = ev.target.result;
				const data = {
					name: file_obj.name,
					size: file_obj.size,
					content: file_content,
					entity_uuid: task.id,
					entity_field: "completed_screenshot",
				};
				setData(data);
			};
			file_reader.readAsArrayBuffer(file_obj);
		}
	};

	const handleInputClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const uploadImageHandler = () => {
		setIsLoading(true);
		if (!data) return;

		uploadFile({
			data,
			needLoader: false,
			successCb: () => {
				changeTaskStatus({
					id: task.id,
					status: ORDER_STATUS.UNVERIFIED,
					needLoader: false,
					successCb: () => {
						clearTasks && clearTasks();
						loadTasks && loadTasks({ page: 1 });
						getMyTasks({ page: 1, limit: 100 });
					},
					finallyCb: () => {
						setStep((prev) => prev + 1);
						setIsLoading(false);
					},
				});
			},
		});
	};

	return (
		<div className={classes.wrapper}>
			<CloseButton onClick={() => setStep((prev) => prev - 1)} />
			<h2 className="mt-4">Прикрепите скриншот, подтверждающий выполнение задания</h2>
			{selectedImage && (
				<div className={classes.previewContainer}>
					<p className={`${classes.label} text-sm-medium`}>Скриншот</p>
					<img
						src={selectedImage}
						alt="Selected"
						className={classes.previewImage}
						onClick={handleInputClick}
					/>
				</div>
			)}
			<div className={classes.buttonContainer}>
				{data && (
					<BrandButton
						className={`${classes.uploadButton} btn-brand-xl`}
						onClick={uploadImageHandler}
						disabled={!data || isAppLoading}
					>
						На проверку
					</BrandButton>
				)}
				<BrandButton
					className={`${classes.uploadButton} btn-brand-outline`}
					onClick={handleInputClick}
					disabled={isAppLoading}
				>
					{data ? "Загрузить другой" : "Загрузить скриншот"}
				</BrandButton>
			</div>
			<input
				type="file"
				ref={fileInputRef}
				className={classes.fileInput}
				accept="image/*"
				onChange={handleFileChange}
			/>
		</div>
	);
};

export default UploadImage;
