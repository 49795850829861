import { create } from "zustand";
import useRequestStore from "./RequestStore";
import useModuleStore from "./ModuleStore";

const useTaskStore = create((set, get) => ({
	tasks: {
		potentialTasks: null,
		myTasks: null,
	},
	currentTask: null,
	taskListForCheck: [],
	setTaskListForCheck: (data) => {
		set({
			taskListForCheck: data,
		});
	},
	setCurrentTask: (data) => {
		set({
			currentTask: data,
		});
	},
	setTasks: (type, data) => {
		set((state) => ({
			tasks: {
				...state.tasks,
				[type]: data,
			},
		}));
	},

	getPotentialTasks: ({ page, limit, filters, successCb, finallyCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("order_user_activities:list_potential", {
			requestData: { page, limit, filters },
			successCb: (responseData) => {
				successCb && successCb(responseData);
				get().setTasks("potentialTasks", responseData);
			},
			errorCb: (error) => {
				console.error("Ошибка получения потенциальных заданий:", error.title);
			},
			finallyCb: () => {
				finallyCb && finallyCb();
			},
		});
	},

	getMyTasks: ({ page, limit, successCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("order_user_activities:list_active", {
			requestData: { page, limit },
			successCb: (responseData) => {
				get().setTasks("myTasks", responseData);
				successCb && successCb(responseData);
			},
			errorCb: (error) => {
				console.error("Ошибка получения моих заданий:", error.title);
			},
		});
	},
	getTaskHistory: ({ page, limit, filters, successCb, finallyCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("order_user_activities:list", {
			requestData: { page, limit, filters },
			successCb: (responseData) => {
				successCb && successCb(responseData);
			},
			errorCb: (error) => {
				console.error("Ошибка получения истории заданий:", error.title);
			},
			finallyCb: () => {
				finallyCb && finallyCb();
			},
		});
	},
	getTaskInfo: (id) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("order_user_activities:view", {
			requestData: { entity_id: id },
			successCb: (responseData) => {
				get().setCurrentTask(responseData);
			},
			errorCb: (error) => {
				console.error("Ошибка получения задания:", error.title);
			},
		});
	},

	takeTask: ({ id, successCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("order_user_activities:create", {
			requestData: { order_activity_id: id },
			successCb: (responseData) => {
				successCb && successCb(responseData.item);
			},
			errorCb: (error) => {
				console.error("Ошибка взятия в работу:", error.title);
			},
		});
	},
	changeTaskStatus: ({
		id,
		status,
		comment,
		verified_comment,
		successCb,
		finallyCb,
		needLoader = true,
	}) => {
		const getTaskModule = useModuleStore.getState().getOrderUserActivitiesModule;
		const orderModule = getTaskModule();
		if (!orderModule) return;

		const statusId = orderModule?.states.find((el) => el.code === status).id;
		const handleRequest = useRequestStore.getState().handleRequest;
		const event = Array.isArray(id)
			? "order_user_activities:change_state_multiple"
			: "order_user_activities:change_state";
		handleRequest(event, {
			requestData: {
				entity_id: id,
				state_id: statusId,
				cancellation_comment: comment,
				verified_comment,
			},
			needLoader,
			successCb: (responseData) => {
				successCb && successCb();
				console.log(`Статус задания изменен ${JSON.stringify(responseData)}`);
			},
			errorCb: (error) => {
				console.error(error.title);
			},
			finallyCb: () => {
				finallyCb && finallyCb();
			},
		});
	},
	uploadFile: ({ data, successCb, needLoader = true }) => {
		console.log("uploadFile", needLoader);
		const { entity_uuid, entity_field, name, size, content } = data;
		const handleRequest = useRequestStore.getState().handleRequest;
		const getTaskModule = useModuleStore.getState().getOrderUserActivitiesModule;
		const orderActivityModule = getTaskModule();

		handleRequest("files:upload", {
			requestData: {
				module_id: orderActivityModule?.id,
				entity_field,
				entity_uuid,
				name,
				size,
				content,
			},
			needLoader,
			successCb: (responseData) => {
				successCb && successCb();
			},
			errorCb: (error) => {
				console.error(error.title);
			},
		});
	},
}));

export default useTaskStore;
