import { ReactComponent as Close } from "@assets/icons/button/cross.svg";
import React from "react";
import BrandButton from "../Button/BrandButton";

const CloseButton = ({ className, onClick }) => {
	return (
		<BrandButton className={`btn-brand-icon ${className ? className : ""}`} onClick={onClick}>
			<Close />
		</BrandButton>
	);
};

export default CloseButton;
