import { LOGIN_WITH_TOKEN } from "@/const/token";
import ConnectWebSocket from "@components/ConnectWebSocket/ConnectWebSocket";
import IsValidToken from "@hooks/IsValidToken";
import useAuthStore from "@store/AuthStore";
import React, { useEffect } from "react";
import useRouter from "@hooks/useRouter";
import AppLayout from "./AppLayout";

function App() {
	const { isWebSocketConnected, isAuth, login, logout } = useAuthStore();
	const { navigate, location } = useRouter();
	const isValidToken = IsValidToken();

	useEffect(() => {
		console.log(location.pathname);
		if (location.pathname === "/complete_registration") return;
		if (!isWebSocketConnected) return;

		if (isValidToken && !isAuth) {
			login(navigate, isValidToken, LOGIN_WITH_TOKEN);
			return;
		}

		if (!isValidToken && isAuth) {
			logout();
		}

		if (!isValidToken && !isAuth) {
			navigate("/signin");
		}
	}, [isWebSocketConnected, isValidToken, isAuth]);

	return (
		<>
			<ConnectWebSocket />
			<AppLayout />
		</>
	);
}
export default App;
