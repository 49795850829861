import React from "react";
import useAuthStore from "@store/AuthStore";
import classes from "./SidebarProfile.module.css";

const SidebarProfile = ({ className, onClick }) => {
	const { profileData } = useAuthStore();

	return (
		<div className={`${className || ""} ${classes.sidebarProfileContainer}`} onClick={onClick}>
			<img
				src={profileData?.photo || "/profile/Avatar2.png"}
				alt="avatar_default"
				width={48}
				height={48}
			/>
		</div>
	);
};

export default SidebarProfile;
