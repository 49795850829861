import React from "react";
import { Form } from "react-bootstrap";
import classes from "./BrandCheckbox.module.css";

const BrandCheckbox = ({ checked, onChange, onClick, label, name, className }) => {
	const handleChange = (e) => {
		onChange(e.target.checked);
	};

	return (
		<div className={`${className || ""} text-sm-medium`}>
			<Form.Check
				type="checkbox"
				id={name || "custom-checkbox"}
				name={name}
				label={label}
				checked={checked}
				onChange={handleChange}
				onClick={onClick}
				className={classes.checkbox}
			/>
		</div>
	);
};

export default BrandCheckbox;
