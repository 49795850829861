import { ReactComponent as Loader } from "@assets/icons/loader/spinnerLoader.svg";
import React from "react";
import classes from "./AppLoader.module.css";

const AppLoader = () => {
	return (
		<div className={classes.loaderOverlay}>
			<Loader width={100} height={100} className={classes.loader} />
		</div>
	);
};

export default AppLoader;
