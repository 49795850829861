import { useEffect } from "react";
const InfiniteScrollLoader = ({ fetching, setFetching, loadedTasks, totalCount }) => {
	const scrollHandler = () => {
		const target = document.documentElement;

		if (
			target.scrollHeight - (target.scrollTop + window.innerHeight) < 200 &&
			loadedTasks.length < totalCount
		) {
			setFetching(true);
		}
	};

	useEffect(() => {
		document.addEventListener("scroll", scrollHandler);
		return () => {
			document.removeEventListener("scroll", scrollHandler);
		};
	}, [loadedTasks, totalCount, fetching]);

	return null;
};

export default InfiniteScrollLoader;
