import { ORDER_COLORS } from "@const/colors";
import { ORDER_STATUS } from "@const/order";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { calculateDelay, convertDatetime } from "@helpers/day";
import { getFromLocalStorage, saveToLocalStorage } from "@helpers/localStorage";
import { Divider, MantineProvider, Menu } from "@mantine/core";
import useOrderStore from "@store/OrderStore";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./OrdersTable.module.css";
import { formatDate } from "@helpers/day";

const OrdersTable = () => {
	const { orderList, totalOrders, getOrderList } = useOrderStore();

	const LOCAL_STORAGE_KEY = "ordersTablePageSize";

	const savedPageSize = getFromLocalStorage(LOCAL_STORAGE_KEY);

	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: savedPageSize || 15,
	});
	const [sorting, setSorting] = useState([]);

	useEffect(() => {
		getOrderList({
			page: pagination.pageIndex + 1,
			limit: pagination.pageSize,
			sort: sorting,
		});
	}, [pagination.pageIndex, pagination.pageSize, getOrderList, sorting]);

	useEffect(() => {
		saveToLocalStorage(LOCAL_STORAGE_KEY, pagination.pageSize);
	}, [pagination.pageSize]);

	const getRowColor = (row) => ORDER_COLORS[row.state.code];

	const formatDelay = (delay) => {
		const parts = [];
		if (delay?.days) parts.push(`${delay.days} д`);
		if (delay?.hours) parts.push(`${delay.hours} ч`);
		if (delay?.minutes) parts.push(`${delay.minutes} м`);
		return parts.length ? `на ${parts.join(" ")}` : "";
	};

	const renderElement = (cell, original, elem) => {
		let isCancelledOrExpired =
			original.state.code === ORDER_STATUS.EXPIRED ||
			original.state.code === ORDER_STATUS.CANCELLED;

		const isStatusColumn = cell.column.id === "state";

		return (
			<div className={`${classes.textWrapper} text-sm-medium`}>
				<div
					style={{
						color: isCancelledOrExpired || isStatusColumn ? getRowColor(original) : "",
						textDecoration: "none",
					}}
				>
					{cell.getValue()}
				</div>
				{elem || ""}
			</div>
		);
	};

	const calculateCount = (original, field) => {
		return original.order_activities.reduce((prev, cur) => prev + cur[field], 0);
	};

	const renderCell = (cell) => {
		const { original } = cell.row;
		if (cell.column.id === "quantity") {
			const tasksCount = calculateCount(original, "quantity");
			const taskCountUsed = calculateCount(original, "quantity_used");
			const elem = (
				<span className="text-xs-medium">
					{taskCountUsed} из {tasksCount}
				</span>
			);

			return renderElement(cell, original, elem);
		}
		if (cell.column.id === "state" && original.state.code === ORDER_STATUS.EXPIRED) {
			const delay = calculateDelay(original.finished_on);
			if (!delay) return renderElement(cell, original);

			const elem = (
				<div className={`text-xs-medium ${classes.additionalText}`}>{formatDelay(delay)}</div>
			);
			return renderElement(cell, original, elem);
		}
		if (cell.column.id === "state" && original.state.code === ORDER_STATUS.PENDING) {
			const elem = (
				<div className={`text-xs-medium ${classes.additionalText}`}>
					на {formatDate({ dateString: original.started_on, withTime: true })}
				</div>
			);
			return renderElement(cell, original, elem);
		}

		return renderElement(cell, original);
	};

	const columnData = [
		{ accessor: "number", header: "Номер" },
		{ accessor: "state.title", header: "Статус" },
		{ accessor: "customer.name", header: "Заказчик" },
		{
			accessor: "quantity",
			header: "Заданий выполнено",
		},
		{
			accessor: "finished_on",
			header: "Окончание заказа",
			isTime: true,
		},
	];

	const columns = useMemo(() => {
		return columnData.map(({ accessor, header, isTime }) => ({
			accessorFn: (row) => {
				if (accessor === "quantity") {
					return null;
				}
				if (isTime) {
					return convertDatetime(row[accessor] || null) || "Нет данных";
				}
				return accessor.split(".").reduce((obj, key) => (obj ? obj[key] : undefined), row);
			},
			id: accessor.replace(/\.\w+$/, ""),
			header: header,
			Header: <i className="text-xs-medium">{header}</i>,
			enableSorting: accessor === "quantity" ? false : true,
			Cell: ({ cell }) => renderCell(cell),
			size: accessor === "number" ? 50 : 300,
		}));
	}, []);

	const fontAwesomeIcons = {
		IconSortAscending: (props) => (
			<FontAwesomeIcon icon={faSortUp} {...props} className={classes.icon} />
		),

		IconSortDescending: (props) => (
			<FontAwesomeIcon icon={faSortDown} className={classes.icon} {...props} />
		),
	};

	const table = useMantineReactTable({
		columns,
		data: orderList || [],
		enableStickyHeader: true,
		manualPagination: true,
		onPaginationChange: setPagination,
		enableSorting: true,
		manualSorting: true,
		onSortingChange: setSorting,
		state: { pagination, sorting },
		rowCount: totalOrders,
		paginationDisplayMode: "pages",
		enableColumnActions: false,
		enableTopToolbar: false,

		mantinePaginationProps: {
			color: "violet",
		},
		mantineEditTextInputProps: {
			width: "80px",
		},
		icons: fontAwesomeIcons,

		mantineTableBodyRowProps: ({ row }) => ({
			component: Link,
			to: `/orders/${row.original.id}`,
			className: classes.tableRow,
		}),

		renderColumnActionsMenuItems: ({ internalColumnMenuItems }) => {
			return (
				<>
					{internalColumnMenuItems}
					<Divider />
					<Menu.Item>Item 1 test</Menu.Item>
					<Menu.Item>Item 2 test</Menu.Item>
				</>
			);
		},

		localization: {
			noRecordsToDisplay: "Записей не найдено",
			rowsPerPage: "Заказов на странице",
		},
		mantinePaperProps: { className: classes.table },
	});

	return (
		<div className={classes.tableWrapper}>
			<MantineProvider
				theme={{
					fontFamily: "Inter",
					defaultRadius: "16px",
					white: "#F2F4F7",
				}}
			>
				<MantineReactTable table={table} />
			</MantineProvider>
		</div>
	);
};

export default OrdersTable;
