import useModalStore from "@store/ModalStore";
import useModuleStore from "@store/ModuleStore";
import useOrderStore from "@store/OrderStore";
import BrandInput from "@ui/Input/BrandInput";
import BrandSearchInput from "@ui/SearchInput/BrandSearchInput";
import BrandSwitch from "@ui/Switch/BrandSwitch";
import BrandTextArea from "@ui/TextArea/BrandTextArea";
import React, { useEffect, useRef } from "react";
import { convertISO8601Duration } from "../../../helpers/day";
import useRouter from "@hooks/useRouter";
import CreateCustomer from "../../CreateCustomer/CreateCustomer";
import classes from "./CreateOrderForm.module.css";
import OrderSum from "./OrderSum/OrderSum";
import TaskBlock from "./Tasks/TaskBlock/TaskBlock";
import TaskList from "./Tasks/TaskList/TaskList";

export const CreateOrderForm = ({ isEdit }) => {
	const {
		getCustomerList,
		clearCustomerList,
		customerList,
		socialNetworkList,
		getSocialNetworkList,
		getInstagramInfo,
		orderData,
		activeTasks,
		setActiveTasks,
		isFieldValid,
		selectedSocial,
		setSelectedSocial,
		updateOrderData,
		getOrderInfo,
		currentOrder,
		resetOrderForm,
		getTaskTitle,
	} = useOrderStore();
	const { moduleList, getSocialNetworkModule, getActiveStateForModule } = useModuleStore();
	const { showModal, setModalMessage, hideModal } = useModalStore();
	const { params } = useRouter();
	const { id } = params;

	const taskRefs = useRef([]);

	useEffect(() => {
		const currentRef = taskRefs.current[taskRefs.current.length - 1];
		if (currentRef) {
			currentRef.scrollIntoView({ behavior: "smooth" });
		}
	}, [activeTasks]);

	useEffect(() => {
		isEdit && getOrderInfo(id);
	}, [id, isEdit]);

	useEffect(() => {
		if (currentOrder && isEdit) {
			const currentOrderData = {
				completionTime: convertISO8601Duration(currentOrder.completion_time, false) || "",
				customer: currentOrder.customer || "",
				url: currentOrder.url || "",
				description: currentOrder.description || currentOrder.comment || "",
				isDifferedOrder: currentOrder.isDifferedOrder || false,
			};

			Object.entries(currentOrderData).forEach(([key, value]) => {
				updateOrderData(key, value);
			});

			setActiveTasks(currentOrder.order_activities || []);
		} else {
			resetOrderForm();
		}
	}, [currentOrder, isEdit]);

	useEffect(() => {
		if (!moduleList?.length) return;
		if (!socialNetworkList?.length) {
			let socialNetworkModule = getSocialNetworkModule();
			let activeSocialNetworksId = getActiveStateForModule(socialNetworkModule);

			getSocialNetworkList({
				page: 1,
				limit: 100,
				activeSocialNetworksId,
				filters: { state_id: [activeSocialNetworksId] },
			});
		}
	}, [moduleList]);

	useEffect(() => {
		if (!socialNetworkList?.length) return;
		let instagram = getInstagramInfo();
		setSelectedSocial(instagram);
	}, [socialNetworkList]);

	useEffect(() => {
		if (!activeTasks.some((task) => getTaskTitle(task) === "Комментарий")) {
			orderData.comments = "";
		}
	}, [activeTasks]);

	useEffect(() => {
		return () => {
			hideModal();
		};
	}, []);

	const handleFetchCustomerList = (searchTerm) => {
		getCustomerList({
			page: 1,
			limit: 100,
			filters: { name: searchTerm },
		});
	};

	const handleSelectCustomer = (customer) => {
		orderData.customer = customer;
	};

	const onTaskCountChange = (count, task) => {
		setActiveTasks(
			activeTasks.map((currentTask) =>
				currentTask.id === task.id ? { ...currentTask, quantity: count } : currentTask
			)
		);
	};

	const createNewCustomer = () => {
		showModal(true);
		setModalMessage(<CreateCustomer />);
	};

	return (
		<form className={classes.form}>
			<OrderSum activeTasks={activeTasks} />

			<div className="row">
				<div className="col-md-6 col-12 mb-3">
					<BrandSearchInput
						fetchData={handleFetchCustomerList}
						clearData={clearCustomerList}
						options={customerList}
						onSelect={handleSelectCustomer}
						placeholder="Не указан"
						field="name"
						label="Заказчик"
						className="position-relative"
						name="name"
						autoComplete="off"
						defaultLiText="+ Добавить нового"
						valid={isFieldValid.customer}
						defaultLiOnClick={createNewCustomer}
						value={orderData.customer}
					/>
				</div>
				<div className="col-md-6 col-12 mb-3">
					<BrandInput
						type="number"
						name="order_time"
						label="Срок выполнения заказа"
						placeholder="0 ч"
						className=""
						unit="ч"
						value={orderData.completionTime}
						onChange={(v) => updateOrderData("completionTime", v)}
						max={1000}
						min={0}
						autoComplete="off"
						valid={isFieldValid.completionTime}
					/>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-md-6 col-12 mb-3">
					<BrandTextArea
						label="Ссылка"
						placeholder="Ссылка на задание должна быть рабочая"
						className=""
						value={orderData.url}
						onChange={(v) => updateOrderData("url", v)}
						valid={isFieldValid.url}
					/>
				</div>
				<div className="col-md-6 col-12 mb-3">
					<BrandTextArea
						label="Описание"
						placeholder="Что нужно учесть"
						className=""
						value={orderData.description}
						onChange={(v) => updateOrderData("description", v)}
						valid={isFieldValid.description}
					/>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-12">
					<BrandSwitch
						checked={orderData.isDefferedOrder}
						onChange={(v) => updateOrderData("isDefferedOrder", v)}
						label="Отложенный заказ"
						name="defferedOrder"
						className="my-4"
					/>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col-12">
					<TaskList
						valid={isFieldValid.activeTasks}
						social_network_id={selectedSocial?.id}
						isEdit={isEdit}
					/>
				</div>
			</div>

			{activeTasks?.length > 0 &&
				activeTasks.map((task, index) => {
					return (
						<TaskBlock
							key={task.id}
							title={getTaskTitle(task)}
							onValueChange={(count) => onTaskCountChange(count, task)}
							quantity={task.quantity}
							ref={(el) => (taskRefs.current[index] = el)}
						/>
					);
				})}
		</form>
	);
};
