import useRouter from "@hooks/useRouter";
import useLoaderStore from "@store/LoaderStore";
import BrandButton from "@ui/Button/BrandButton";
import BrandInput from "@ui/Input/BrandInput";
import Logo from "@ui/Logo/Logo";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import classes from "./CompleteRegistrationForm.module.css";
import RegistrationSuccess from "./RegistrationSuccess/RegistrationSuccess";

const CompleteRegistrationForm = ({
	setFormRequestError,
	formRequestError,
	className,
	onSubmit,
	token,
	email,
}) => {
	const [password, setPassword] = useState("");
	const [acceptPassword, setAcceptPassword] = useState("");
	const [formErrors, setFormErrors] = useState({});
	const [regStep, setRegStep] = useState(0);
	const { isAppLoading } = useLoaderStore();
	const { navigate } = useRouter();

	const validatePassword = () => {
		if (!password.trim()) {
			return "Без пароля не получится";
		}
		if (password.trim().length < 8) {
			return "Пароль должен содержать хотя бы 8 символов.";
		}
		if (password !== acceptPassword) {
			return "Пароли не совпадают";
		}
		return null;
	};

	// const validatePhone = () => {
	// 	const phoneRegex = /^(?:\+7|8)\d{10}$/;
	// 	if (!phone.trim()) {
	// 		return "Введите номер телефона";
	// 	}
	// 	if (!phoneRegex.test(phone)) {
	// 		return "Номер телефона должен быть в российском формате (начинается с +7 или 8 и содержит 10 цифр)";
	// 	}
	// 	return null;
	// };

	const handleNextStep = (e) => {
		e.preventDefault();
		setFormRequestError(null);

		const passwordError = validatePassword();

		if (passwordError) {
			setFormErrors((prev) => ({
				...prev,
				password: passwordError,
			}));
		} else {
			setFormErrors({});
			onSubmit({ password, token }, () => setRegStep((prev) => prev + 1));
		}
	};

	return (
		<Form className={`${classes.brandForm} ${className}`}>
			<div className={classes.logoWrapper}>
				<Logo className={`${classes.logo}`} />
			</div>

			<div className={classes.inputWrapper}>
				{regStep === 0 ? (
					<>
						<h1 className={classes.title}>Придумайте пароль</h1>

						{email && <p className={`${classes.email} text-md-regular`}>для аккаунта {email}</p>}

						<BrandInput
							name="password"
							type="password"
							placeholder="Пароль"
							autoComplete="false"
							value={password}
							onChange={setPassword}
							error={formErrors.password}
							className={classes.input}
						/>
						<BrandInput
							name="password"
							type="password"
							placeholder="Подтвердите пароль"
							autoComplete="false"
							value={acceptPassword}
							onChange={setAcceptPassword}
							error={formErrors.acceptPassword}
							className={classes.input}
							showPasswordClue={false}
						/>
						<BrandButton
							className={`btn-brand-xl w-100 ${isAppLoading ? "disabled" : ""}`}
							onClick={handleNextStep}
							disabled={isAppLoading}
						>
							Продолжить
						</BrandButton>
						<p className={classes.error}>{formRequestError}</p>
					</>
				) : (
					<RegistrationSuccess
						onClick={(e) => {
							e.preventDefault();
							navigate("/signin");
						}}
					/>
				)}
			</div>
		</Form>
	);
};

export default CompleteRegistrationForm;
