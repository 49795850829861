import UpdateUserInfoForm from "@components/Forms/UpdateUserInfoForm/UpdateUserInfoForm";
import PageTitle from "@components/ui/PageTitle/PageTitle";
import useProfileStore from "@store/ProfileStore";
import React, { useEffect } from "react";
import classes from "./ProfilePageEdit.module.css";
import useSystemStore from "@store/SystemStore";
import ProfilePageEditMobileBottomBar from "./mobileBottomBar/ProfilePageEditMobileBottomBar";

const ProfilePageEdit = () => {
	const { profileInfo } = useProfileStore();
	const { getSystemSettings } = useSystemStore();

	useEffect(() => {
		getSystemSettings();
	}, []);

	if (!profileInfo) return;

	return (
		<div className={classes.wrapper}>
			<PageTitle title="Редактирование профиля" />
			<UpdateUserInfoForm />
			<ProfilePageEditMobileBottomBar />
		</div>
	);
};

export default ProfilePageEdit;
