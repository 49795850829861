import React, { useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import InfiniteScrollLoader from "@components/InfiniteScrollLoader/InfiniteScrollLoader";
import PartingWords from "@components/PartingWords/PartingWords";
import TaskList from "@components/Task/TaskList/TaskList";
import useModalStore from "@store/ModalStore";
import useTaskStore from "@store/TaskStore";
import useTaskLoader from "@hooks/useTaskLoader";
import classes from "./TasksPage.module.css";

const TasksPage = () => {
	const TASK_CODE = {
		ALL: "all",
		AVAILABLE: "available",
		PENDING: "pending",
	};

	const { tasks, getPotentialTasks, getMyTasks } = useTaskStore();
	const { hideModal } = useModalStore();
	const {
		taskStatus,
		fetching,
		totalCount,
		loadedTasks,
		handleTabSelect,
		loadTasks,
		setFetching,
		setLoadedTasks,
		currentPage,
		clearTasks,
	} = useTaskLoader({
		initialTaskStatus: TASK_CODE.ALL,
		fetchTasks: getPotentialTasks,
		itemsPerPage: 5,
	});

	useEffect(() => {
		getMyTasks({ page: 1, limit: 100 });
		return () => hideModal();
	}, [getMyTasks, hideModal]);

	const tabs = [
		{ eventKey: TASK_CODE.ALL, title: "Все" },
		{ eventKey: TASK_CODE.AVAILABLE, title: "Доступные" },
		{ eventKey: TASK_CODE.PENDING, title: "Отложенные" },
	];

	return (
		<div>
			<PartingWords />
			<div className={classes.content}>
				<InfiniteScrollLoader
					fetching={fetching}
					setFetching={setFetching}
					loadedTasks={loadedTasks}
					totalCount={totalCount}
					currentPage={currentPage}
				/>
				<div className={classes.allTaskWrapper}>
					<Tabs
						defaultActiveKey={TASK_CODE.ALL}
						id="task-tab"
						className={classes.tabWrapper}
						onSelect={handleTabSelect}
					>
						{tabs.map((tab) => (
							<Tab
								key={tab.eventKey}
								eventKey={tab.eventKey}
								title={tab.title}
								className={`${classes.tab}`}
								disabled={taskStatus === tab.eventKey}
							>
								<TaskList
									tasks={loadedTasks}
									loadTasks={loadTasks}
									setLoadedTasks={setLoadedTasks}
									clearTasks={clearTasks}
								/>
							</Tab>
						))}
					</Tabs>
				</div>
				<div className={classes.myTaskWrapper}>
					<p className={`text-md-semibold ${classes.title}`}>Мои задания</p>
					<TaskList
						tasks={tasks?.myTasks?.items}
						loadTasks={loadTasks}
						setLoadedTasks={setLoadedTasks}
						clearTasks={clearTasks}
						emptyList="Вы еще не взяли ни одного задания"
					/>
				</div>
			</div>
		</div>
	);
};

export default TasksPage;
