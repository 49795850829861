import React from "react";
import { Form } from "react-bootstrap";
// import classes from "./BrandRadio.module.css";

const BrandRadio = ({ checked, onChange, onClick, label, name, value, className }) => {
	const handleChange = (e) => {
		onChange(e.target.value);
	};

	return (
		<div className={`${className || ""} text-sm-medium`}>
			<Form.Check
				type="radio"
				id={name || "custom-radio"}
				name={name}
				label={label}
				checked={checked}
				onChange={handleChange}
				onClick={onClick}
				value={value}
				// className={classes.radio}
			/>
		</div>
	);
};

export default BrandRadio;
