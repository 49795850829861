import { ReactComponent as Complete } from "@assets/icons/task/complete.svg";
import BrandButton from "@components/ui/Button/BrandButton";
import React from "react";
import classes from "./RegistrationSuccess.module.css";
const RegistrationSuccess = ({ onClick }) => {
	return (
		<div className={classes.wrapper}>
			<Complete className={classes.image} />
			<div className={classes.text}>
				<p>Вы зарегистрированы.</p>
				<p>Добро пожаловать!</p>
			</div>
			<BrandButton className={`btn-brand-xl ${classes.btn}`} onClick={onClick}>
				Войти
			</BrandButton>
		</div>
	);
};

export default RegistrationSuccess;
