import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { ReactComponent as EyeOff } from "@assets/icons/input/eyeOff.svg";
import { ReactComponent as Eye } from "@assets/icons/input/eyeOn.svg";
import classes from "./BrandInput.module.css";

const BrandInput = ({
	onChange,
	value = "",
	type,
	name,
	autoComplete = "false",
	label,
	placeholder,
	error,
	className,
	unit,
	max,
	min,
	valid = true,
	style,
	showPasswordClue = true,
	onKeyPress,
	disabled,
}) => {
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const togglePasswordVisibility = () => {
		setIsPasswordVisible((prev) => !prev);
	};

	const handleChange = (e) => {
		const newValue = e.target.value;

		if (type === "number") {
			if (newValue === "") {
				onChange("");
			} else {
				const numericValue = parseInt(newValue, 10);
				if (numericValue > max) {
					onChange(max);
				} else if (numericValue < min) {
					onChange(min);
				} else {
					onChange(numericValue);
				}
			}
		} else {
			onChange(newValue);
		}
	};

	return (
		<Form.Group className={`${classes.formGroupWrapper} ${className || ""}`} style={style}>
			{label && (
				<Form.Label
					htmlFor={name}
					className={`text-sm-medium ${classes.label} ${!valid ? classes.error : ""}`}
				>
					{label}
				</Form.Label>
			)}
			<div className={classes.inputContainer}>
				<Form.Control
					name={name}
					type={isPasswordVisible && type === "password" ? "text" : type}
					placeholder={placeholder}
					onChange={handleChange}
					value={value}
					autoComplete={autoComplete}
					className="brand-input"
					max={max}
					min={min}
					onKeyPress={onKeyPress}
					disabled={disabled}
				/>
				{unit && value && (
					<span
						className={classes.unit}
						style={{ left: `${value?.toString().length * 10 + 15}px` }}
					>
						{unit}
					</span>
				)}
				{type === "password" && value && (
					<span onClick={togglePasswordVisibility} className={classes.eyeIcon}>
						{isPasswordVisible ? <EyeOff /> : <Eye />}
					</span>
				)}
			</div>
			{!error && showPasswordClue && type === "password" && (
				<span className={classes.feedbackClue}>Не меньше 8 символов — букв и цифр</span>
			)}
			{error && <span className={classes.feedbackError}>{error}</span>}
		</Form.Group>
	);
};

export default BrandInput;
