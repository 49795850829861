import { ReactComponent as Complete } from "@assets/icons/task/complete.svg";
import BrandButton from "@components/ui/Button/BrandButton";
import useModalStore from "@store/ModalStore";
import React from "react";
import classes from "./TaskSuccess.module.css";

const TaskSuccess = ({ setStep }) => {
	const { hideModal } = useModalStore();

	const successTaskHandler = () => {
		setStep(1);
		hideModal();
	};

	return (
		<div className={classes.wrapper}>
			<Complete className={classes.image} />
			<p className={classes.text}>Отлично! Мы скоро проверим задание</p>
			<BrandButton className={`btn-brand-xl ${classes.btn}`} onClick={successTaskHandler}>
				Хорошо
			</BrandButton>
		</div>
	);
};

export default TaskSuccess;
