import { ReactComponent as Graduation } from "@assets/icons/button/graduation.svg";
import { CreateOrderForm } from "@components/Forms/CreateOrderForm/CreateOrderForm";
import MobileBottomBar from "@components/MobileBottomBar/MobileBottomBar";
import BrandButton from "@components/ui/Button/BrandButton";
import PageTitle from "@ui/PageTitle/PageTitle";
import React from "react";
import useRouter from "@hooks/useRouter";
import useOrderStore from "@store/OrderStore";
import classes from "./CreateOrder.module.css";

const CreateOrderPage = () => {
	const { location, navigate, params } = useRouter();
	const { id } = params;
	const { createOrderHandler } = useOrderStore();

	const isEdit = location.pathname.includes("edit");

	return (
		<>
			<PageTitle title={isEdit ? "Редактирование заказа" : "Новый заказ"} className="mb-5" />
			<CreateOrderForm isEdit={isEdit} />
			<MobileBottomBar>
				<div className="d-flex gap-2 w-100">
					<BrandButton
						className={`${classes.newOrderButton} btn-brand-xl`}
						onClick={(e) => createOrderHandler(e, navigate, isEdit, id)}
					>
						{isEdit ? "Редактировать заказ" : "Создать заказ"}
					</BrandButton>
					<BrandButton className={`${classes.instructionButton} btn-brand-icon`}>
						<Graduation />
					</BrandButton>
				</div>
			</MobileBottomBar>
		</>
	);
};

export default CreateOrderPage;
