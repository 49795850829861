import { ReactComponent as Plus } from "@assets/icons/button/plus.svg";
import BrandButton from "@components/ui/Button/BrandButton";
import MobileBottomBar from "@components/MobileBottomBar/MobileBottomBar";
import OrdersTable from "@components/OrdersTable/OrdersTable";
import React from "react";
import PageTitle from "@ui/PageTitle/PageTitle";
import classes from "./OrderList.module.css";

const OrdersPage = () => {
	return (
		<>
			<PageTitle title="Все заказы" className="mb-5" />
			<OrdersTable />

			<MobileBottomBar>
				<BrandButton
					to="/orders/create"
					className={`${classes.newOrderButton} btn-brand-xl w-100`}
					icon={<Plus />}
				>
					Новый заказ
				</BrandButton>
			</MobileBottomBar>
		</>
	);
};

export default OrdersPage;
