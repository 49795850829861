import { ReactComponent as ChevronLeft } from "@assets/icons/button/chevronLeft.svg";
import React from "react";
import useRouter from "@hooks/useRouter";
import BrandButton from "../Button/BrandButton";

const BackButton = ({ className }) => {
	let { navigate } = useRouter();
	return (
		<BrandButton
			className={`btn-brand-icon ${className ? className : ""}`}
			onClick={() => navigate(-1)}
		>
			<ChevronLeft />
		</BrandButton>
	);
};

export default BackButton;
