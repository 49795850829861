import React, { useState } from "react";
import PageTitle from "@ui/PageTitle/PageTitle";
import BrandInput from "@ui/Input/BrandInput";
import BrandButton from "@ui/Button/BrandButton";
import useUserStore from "@store/UserStore";
import useModalStore from "@store/ModalStore";

const CreateCustomer = () => {
	const { createCustomer } = useUserStore();
	const [customerNick, setCustomerNick] = useState("");
	const { hideModal } = useModalStore();

	return (
		<section className="d-flex flex-column gap-5" style={{ width: "350px" }}>
			<PageTitle title="Новый заказчик" />
			<BrandInput
				placeholder="Никнейм"
				label="Никнейм в инстаграм"
				value={customerNick}
				onChange={setCustomerNick}
			/>
			<BrandButton
				className="btn-brand-xl w-100"
				onClick={() => createCustomer({ customerNick, hideModal })}
			>
				Добавить
			</BrandButton>
		</section>
	);
};

export default CreateCustomer;
