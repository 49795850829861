import React from "react";
import BrandButton from "@components/ui/Button/BrandButton";
import { ORDER_COLORS } from "@const/colors";
import { ORDER_STATUS } from "@const/order";
import useHeaderStore from "@store/HeaderStore";
import useOrderStore from "@store/OrderStore";
import BackButton from "@ui/BackButton/BackButton";
import useRouter from "@hooks/useRouter";

const OrderItemHeader = () => {
	const { params } = useRouter();
	const { id } = params;
	const { isHeaderFixed } = useHeaderStore();
	const { currentOrder, changeOrderStatus } = useOrderStore();

	return (
		<div className="w-100 d-flex align-items-center gap-5 justify-content-between">
			<div className="d-flex gap-3">
				<BackButton className="mobile-hide" />
				{isHeaderFixed && (
					<div>
						<p
							className="text-sm-medium"
							style={{ color: ORDER_COLORS[currentOrder?.state?.code] }}
						>
							{currentOrder?.state?.title}
						</p>
						<p className="text-md-medium">Заказ №{currentOrder?.number}</p>
					</div>
				)}
			</div>
			{currentOrder?.state?.code === ORDER_STATUS.DRAFT && (
				<div className="d-flex gap-3">
					<BrandButton
						className="btn-brand-outline mobile-hide"
						onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.CANCELLED })}
					>
						Отменить
					</BrandButton>
					<BrandButton
						className="btn-brand-xl mobile-hide"
						onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.APPROVED })}
					>
						В работу
					</BrandButton>
				</div>
			)}
			{currentOrder?.state?.code === ORDER_STATUS.PENDING && (
				<div className="d-flex gap-3">
					<BrandButton
						className="btn-brand-outline mobile-hide"
						onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.CANCELLED })}
					>
						Отменить
					</BrandButton>
					<BrandButton
						className="btn-brand-outline mobile-hide"
						onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.APPROVED })}
					>
						В работу
					</BrandButton>
					<BrandButton className="btn-brand-xl mobile-hide">Разрешить бронировать</BrandButton>
				</div>
			)}
			{currentOrder?.state?.code === ORDER_STATUS.APPROVED && (
				<BrandButton
					className="btn-brand-xl mobile-hide"
					onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.UNVERIFIED })}
				>
					На проверку
				</BrandButton>
			)}
			{currentOrder?.state?.code === ORDER_STATUS.UNVERIFIED && (
				<div className="d-flex gap-3">
					<BrandButton
						className="btn-brand-outline mobile-hide"
						onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.UNCOMPLETED })}
					>
						Выполнен частично
					</BrandButton>
					<BrandButton
						className="btn-brand-xl mobile-hide"
						onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.COMPLETED })}
					>
						Выполнен
					</BrandButton>
				</div>
			)}
			{currentOrder?.state?.code === ORDER_STATUS.EXPIRED && (
				<BrandButton
					className="btn-brand-xl mobile-hide"
					onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.UNVERIFIED })}
				>
					На проверку
				</BrandButton>
			)}
		</div>
	);
};

export default OrderItemHeader;
