import AuthForm from "@/components/Forms/AuthForm/AuthForm";
import { LOGIN_WITHOUT_TOKEN } from "@/const/token";
import useAuthStore from "@/store/AuthStore";
import React, { useState } from "react";
import useRouter from "@hooks/useRouter";
import classes from "./AuthPage.module.css";

const AuthPage = () => {
	const { navigate } = useRouter();
	const { login } = useAuthStore();
	const [formRequestError, setFormRequestError] = useState(null);

	const authHandler = (userData) => {
		login(navigate, userData, LOGIN_WITHOUT_TOKEN, setFormRequestError);
	};

	return (
		<div className={classes.authPageContainer}>
			<AuthForm
				onSubmit={authHandler}
				formRequestError={formRequestError}
				setFormRequestError={setFormRequestError}
			/>
		</div>
	);
};

export default AuthPage;
