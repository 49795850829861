import { ReactComponent as ChangeRole } from "@assets/icons/sidebar/changeRole.svg";
import useRouter from "@hooks/useRouter";
import useAuthStore from "@store/AuthStore";
import useLoaderStore from "@store/LoaderStore";
import BrandDropdown from "@ui/Dropdown/BrandDropdown";
import BrandLink from "@ui/Link/BrandLink";
import React from "react";
import { Dropdown } from "react-bootstrap";
import SidebarProfile from "../SidebarProfile/SidebarProfile";
import classes from "./SidebarMenu.module.css";

const SidebarMenu = ({ menu }) => {
	const { userRoles, userRole, setActiveRole } = useAuthStore();
	const { setIsBlick } = useLoaderStore();
	const { navigate } = useRouter();

	const handleRoleChange = (role) => {
		setIsBlick(true);

		setActiveRole({
			role: role.role.code,
			successCb: () => setIsBlick(false),
			navigate,
		});
	};

	return (
		<ul className={classes.sidebarMenu}>
			{menu.map((link) => {
				return (
					<li key={link.to}>
						<BrandLink
							to={link.to}
							title={link.title}
							className={`${window.location.pathname === link.to ? classes.activeLink : ""}`}
						>
							{link.img}
						</BrandLink>
					</li>
				);
			})}
			{userRoles?.length > 1 && (
				<li>
					<BrandDropdown
						triggerComponent={<ChangeRole />}
						dropdownMenu={
							<ul>
								{userRoles?.length > 0 &&
									userRoles.map((role) => {
										const isActive = userRole === role.role.code;
										const useClassName = `${isActive ? classes.activeRole : ""} ${classes.role}`;
										const handleClick = () => handleRoleChange(role);

										return (
											<li key={role.id} onClick={handleClick} className={useClassName}>
												<Dropdown.Item className={useClassName}>{role.role.title}</Dropdown.Item>
											</li>
										);
									})}
							</ul>
						}
						dropdownMenuClassName={classes.dropdownMenuMobile}
					/>
				</li>
			)}
			<li>
				<SidebarProfile
					onClick={() => navigate("/profile")}
					className={`${window.location.pathname === "/profile" ? classes.activeProfile : ""}`}
				/>
			</li>
		</ul>
	);
};

export default SidebarMenu;
