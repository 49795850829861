import React from "react";
import classes from "./ButtonContainer.module.css";
import BrandButton from "@components/ui/Button/BrandButton";
import { ORDER_STATUS } from "@const/order";
import useTaskStore from "@store/TaskStore";
import { ReactComponent as Check } from "@assets/icons/button/check.svg";
import { ReactComponent as Close } from "@assets/icons/button/closeRed.svg";

const ButtonContainer = ({ setShowCancelledBlock, removeCheckedTask }) => {
	const { changeTaskStatus, taskListForCheck } = useTaskStore();
	const approveTaskHandler = () => {
		const idList = taskListForCheck.map((e) => e.id);
		changeTaskStatus({
			id: idList,
			status: ORDER_STATUS.COMPLETED,
			successCb: () => {
				removeCheckedTask();
			},
		});
	};

	const cancelTaskHandler = () => {
		setShowCancelledBlock(true);
	};

	const taskHasUnverified = taskListForCheck.find(
		(task) => task.state.code === ORDER_STATUS.UNVERIFIED
	);

	return (
		<div>
			{taskListForCheck?.length > 0 && taskHasUnverified && (
				<>
					<p className={`text-sm-medium ${classes.taskCount} mb-3`}>
						Выбрано: {taskListForCheck.length}
					</p>
					<div className={classes.buttonContainer}>
						<BrandButton
							className={`${classes.approve} btn-brand-icon`}
							onClick={approveTaskHandler}
						>
							<Check />
						</BrandButton>
						<BrandButton className={`${classes.cancel} btn-brand-icon`} onClick={cancelTaskHandler}>
							<Close />
						</BrandButton>
					</div>
				</>
			)}
		</div>
	);
};

export default ButtonContainer;
