import React from "react";
import useAuthStore from "@store/AuthStore";
import classes from "./MobileMenuFooter.module.css";
import useRouter from "@hooks/useRouter";

const MobileMenuFooter = ({ closeMenu }) => {
	const { profileData } = useAuthStore();
	const { navigate } = useRouter();

	const navigateToProfile = () => {
		navigate("/profile");
		closeMenu();
	};

	return (
		<div className={classes.photoWrapper} onClick={navigateToProfile}>
			<img
				src={profileData?.photo || "/profile/Avatar2.png"}
				alt="avatar_default"
				width={48}
				height={48}
			/>
			<p className="text-md-semibold">{profileData?.name}</p>
		</div>
	);
};

export default MobileMenuFooter;
