import Timer from "@components/Task/Timer/Timer";
import BrandButton from "@components/ui/Button/BrandButton";
import { ORDER_COLORS } from "@const/colors";
import { TASK_TYPE } from "@const/tasks";
import { calculateTimeDifference, formatDate } from "@helpers/day";
import React from "react";
import classes from "./TaskContent.module.css";
import { ORDER_STATUS } from "../../../../const/order";

const TaskContent = ({ task, activityLabel, statusText }) => {
	return (
		<>
			<p className="text-sm-medium">
				<span className={classes.status} style={{ color: ORDER_COLORS[task.order.state.code] }}>
					{statusText}
				</span>
				<span className={classes.number}>• #{task.order.number}</span>
			</p>
			<h2 className={` ${classes.title}`}>{activityLabel}</h2>
			<BrandButton
				to={task.order.url}
				target="_blank"
				className={`btn-brand-non-outline ${classes.href} `}
			>
				Ссылка на пост
			</BrandButton>
			{task.started_on && (
				<div>
					<div>
						<p className={`${classes.label} text-sm-medium`}>Дата старта</p>
						<p className="text-sm-medium">
							{formatDate({ dateString: task.started_on, withTime: true })}
						</p>
					</div>
				</div>
			)}
			{task.finished_on && task.state.code === ORDER_STATUS.APPROVED && (
				<div>
					<p className={`${classes.label} text-sm-medium`}>Срок выполнения</p>
					<Timer initialTime={calculateTimeDifference(task.finished_on)} />
				</div>
			)}
			<div>
				<p className={`${classes.label} text-sm-medium`}>Описание</p>
				<p className="text-md-medium">{task.order.comment}</p>
			</div>
			{task.order_activity?.social_network_activity?.activity?.code === TASK_TYPE.COMMENT && (
				<div>
					<p className={`${classes.label} text-sm-medium`}>Комментарий</p>
					<p className="text-md-medium">{task.order.comments || "Тестовый комментарий"}</p>
				</div>
			)}
			{task.completed_screenshot && (
				<img
					src={`${process.env.REACT_APP_BACKEND_URL}${task.completed_screenshot}`}
					alt="screenshot"
					className={classes.screenshot}
				/>
			)}
			{task.verified_comment && (
				<div>
					<p className={`${classes.label} text-sm-medium`}>Причина отклонения</p>
					<p className="text-md-medium">{task.verified_comment}</p>
				</div>
			)}
		</>
	);
};

export default TaskContent;
