import React from "react";
import useAuthStore from "@store/AuthStore";
import classes from "./PartingWords.module.css";

const PartingWords = () => {
	const { profileData } = useAuthStore();
	return (
		<div className={classes.wrapper}>
			<h1 className={classes.heading}>
				Привет, {profileData?.name}!<br /> Билет в Париж уже не за горами
			</h1>
		</div>
	);
};

export default PartingWords;
