import React, { useEffect } from "react";
import ProfileMainInfo from "@components/ProfileMainInfo/ProfileMainInfo";
import PageTitle from "@components/ui/PageTitle/PageTitle";
import useAuthStore from "@store/AuthStore";
import useProfileStore from "@store/ProfileStore";
import classes from "./ProfilePage.module.css";
import ProfilePageMobileBottomBar from "./mobileBottomBar/ProfilePageMobileBottomBar";

const ProfilePage = () => {
	const { isAuth } = useAuthStore();
	const { profileInfo, getProfileInfo } = useProfileStore();

	useEffect(() => {
		if (!isAuth) return;

		getProfileInfo();
	}, [isAuth]);

	return (
		<>
			<div className={classes.headerWrapper}>
				<img
					src={profileInfo?.photo || "/profile/Avatar2.png"}
					alt="avatar_default"
					width={64}
					height={64}
					className={classes.avatar}
				/>
				<div>
					<p className={`text-sm-medium ${classes.status}`}>{profileInfo?.state?.title}</p>
					<PageTitle title={`${profileInfo?.name} ${profileInfo?.surname}`} />
					<div className={`text-sm-medium ${classes.roleList} `}>
						{profileInfo?.roles.map((role, index) => {
							return (
								<span key={role.role.id} className={classes.role}>
									{role.role.title}
									{index !== profileInfo.roles.length - 1 ? ", " : ""}
								</span>
							);
						})}
					</div>
				</div>
			</div>

			<ProfileMainInfo profileInfo={profileInfo} />
			<ProfilePageMobileBottomBar />
		</>
	);
};

export default ProfilePage;
