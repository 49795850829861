import { create } from "zustand";
import useRequestStore from "./RequestStore";

const useSystemStore = create((set, get) => ({
	systemSettings: null,
	getSystemSettings: () => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("systems:setting", {
			successCb: (responseData) => {
				set({ systemSettings: responseData });
			},
			errorCb: (error) => {
				console.error("Ошибка отправки приглашения:", error);
			},
		});
	},
}));

export default useSystemStore;
