import React, { useState } from "react";
import { Form } from "react-bootstrap";
import RestorePassword from "@components/RestorePassword/RestorePassword";
import BrandButton from "@ui/Button/BrandButton";
import BrandInput from "@ui/Input/BrandInput";
import Agreement from "@components/Agreement/Agreement";
import Logo from "@ui/Logo/Logo";
import { ReactComponent as ChevronLeft } from "@assets/icons/button/chevronLeft.svg";
import { ReactComponent as Cross } from "@assets/icons/button/cross.svg";
import classes from "./AuthForm.module.css";
import useLoaderStore from "@store/LoaderStore";

const AuthForm = ({ setFormRequestError, formRequestError, className, onSubmit }) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [formErrors, setFormErrors] = useState({});
	const [authStep, setAuthStep] = useState(0);
	const [isEditPassword, setIsEditPassword] = useState(false);
	const { isAppLoading } = useLoaderStore();

	const validateEmail = (email) => {
		if (!email.trim()) {
			return "Без почты не получится";
		}
		const isValid =
			/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu.test(
				email
			);
		return isValid ? null : "Неверный формат почты";
	};

	const validatePassword = (password) => {
		if (!password.trim()) {
			return "Без пароля не получится";
		}
		if (password.trim().length < 8) {
			return "Пароль должен содержать хотя бы 8 символов.";
		}
		return null;
	};

	const handleNextStep = (e) => {
		e.preventDefault();
		setFormRequestError(null);

		if (authStep === 0) {
			const emailError = validateEmail(email);
			if (emailError) {
				setFormErrors((prev) => ({ ...prev, email: emailError }));
			} else {
				setFormErrors((prev) => ({ ...prev, email: null }));
				setAuthStep(1);
			}
		}

		if (authStep === 1) {
			const passwordError = validatePassword(password);
			if (passwordError) {
				setFormErrors((prev) => ({ ...prev, password: passwordError }));
			} else {
				setFormErrors({});
				onSubmit({ email, password });
			}
		}
	};

	const handleBackStep = () => {
		setAuthStep((prev) => prev - 1);
		setFormErrors((prev) => ({ ...prev, password: null }));
	};

	if (isEditPassword) return <RestorePassword />;

	return (
		<Form className={`${classes.brandForm} ${className}`}>
			<div className={classes.logoWrapper}>
				{authStep === 0 ? (
					<Logo className={`${classes.logo}`} />
				) : (
					<BrandButton className="btn-brand-icon" onClick={handleBackStep}>
						{authStep === 1 ? <ChevronLeft /> : <Cross />}
					</BrandButton>
				)}
			</div>

			<div className={classes.inputWrapper}>
				{authStep === 0 && (
					<>
						<h1 className={classes.title}>Введите почту</h1>
						<BrandInput
							name="email"
							type="email"
							placeholder="example@mail.ru"
							autoComplete="true"
							value={email}
							onChange={setEmail}
							error={formErrors.email || formRequestError}
							className={classes.input}
						/>
					</>
				)}

				{authStep === 1 && (
					<>
						<h1 className={classes.title}>Введите пароль</h1>
						<BrandInput
							name="password"
							type="password"
							placeholder="Пароль"
							autoComplete="true"
							value={password}
							onChange={setPassword}
							error={formErrors.password || formRequestError}
							className={classes.input}
						/>
					</>
				)}
			</div>

			<BrandButton
				className={`btn-brand-xl w-100 ${isAppLoading ? "disabled" : ""}`}
				onClick={handleNextStep}
				disabled={isAppLoading}
			>
				Продолжить
			</BrandButton>

			{authStep === 0 && <Agreement />}
			{authStep === 1 && <p className={classes.restorePassword}>Восстановить пароль</p>}
		</Form>
	);
};

export default AuthForm;
