import useAuthStore from "@store/AuthStore";
import React, { useEffect, useState } from "react";
import CompleteRegistrationForm from "../../components/Forms/CompleteRegistrationForm/CompleteRegistrationForm";
import classes from "./CompleteRegistration.module.css";
import { useSearchParams } from "react-router-dom";

const CompleteRegistration = () => {
	const { signUpComplete, signUpConfirm } = useAuthStore();
	const [formRequestError, setFormRequestError] = useState(null);

	const [searchParams] = useSearchParams();
	const token = searchParams.get("token");
	const email = searchParams.get("email");

	useEffect(() => {
		setTimeout(() => signUpConfirm(token), 1000);
	}, []);

	const registerHandler = (userData, successCb) => {
		signUpComplete(userData, setFormRequestError, successCb);
	};

	return (
		<div className={classes.wrapper}>
			<CompleteRegistrationForm
				onSubmit={registerHandler}
				formRequestError={formRequestError}
				setFormRequestError={setFormRequestError}
				token={token}
				email={email}
			/>
		</div>
	);
};

export default CompleteRegistration;
