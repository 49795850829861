import BrandButton from "@components/ui/Button/BrandButton";
import useRouter from "@hooks/useRouter";
import useProfileStore from "@store/ProfileStore";
import React, { useEffect } from "react";
import classes from "./Balance.module.css";

const Balance = () => {
	const { profileInfo, getProfileInfo } = useProfileStore();
	const { navigate } = useRouter();

	useEffect(() => {
		getProfileInfo();
	}, []);

	const balance = () => {
		return profileInfo?.wallet?.balance ? `${profileInfo?.wallet?.balance} ₽` : "0 ₽";
	};

	return (
		<BrandButton className={`${classes.balance} btn-brand-xl`} onClick={() => navigate("/profile")}>
			{balance()}
		</BrandButton>
	);
};

export default Balance;
