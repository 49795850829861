import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Dots } from "@assets/icons/button/dotsVertical.svg";
import MobileBottomBar from "@components/MobileBottomBar/MobileBottomBar";
import useOrderStore from "@store/OrderStore";
import BrandButton from "@components/ui/Button/BrandButton";
import useRouter from "@hooks/useRouter";
import { ORDER_STATUS } from "@const/order";
import classes from "./OrderItemMobileBottomBar.module.css";

const OrderItemMobileBottomBar = () => {
	const { params } = useRouter();
	const { id } = params;

	const { currentOrder, changeOrderStatus } = useOrderStore();

	const [showMenu, setShowMenu] = useState(false);
	const menuRef = useRef(null);

	const handleDotsClick = () => {
		setShowMenu((prev) => !prev);
	};

	const handleClickOutside = (event) => {
		if (menuRef.current && !menuRef.current.contains(event.target)) {
			setShowMenu(false);
		}
	};

	useEffect(() => {
		if (showMenu) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [showMenu]);

	return (
		<MobileBottomBar>
			{currentOrder?.state?.code === ORDER_STATUS.DRAFT && (
				<div className={classes.buttonWrapper}>
					<BrandButton
						className="btn-brand-xl w-50"
						onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.APPROVED })}
					>
						В работу
					</BrandButton>
					<BrandButton
						className="btn-brand-outline w-50"
						onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.CANCELLED })}
					>
						Отменить
					</BrandButton>
				</div>
			)}
			{currentOrder?.state?.code === ORDER_STATUS.PENDING && (
				<div className={classes.buttonWrapper}>
					{showMenu ? (
						<div className={classes["menu-container"]} ref={menuRef}>
							<BrandButton
								className="btn-brand-xl w-100"
								onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.APPROVED })}
							>
								В работу
							</BrandButton>
							<BrandButton
								className="btn-brand-outline w-100"
								onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.CANCELLED })}
							>
								Отменить
							</BrandButton>
						</div>
					) : (
						<>
							<BrandButton className="btn-brand-xl w-75">Разрешить бронировать</BrandButton>
							<BrandButton className="btn-brand-icon btn-brand-icon-xl" onClick={handleDotsClick}>
								<Dots />
							</BrandButton>
						</>
					)}
				</div>
			)}

			{currentOrder?.state?.code === ORDER_STATUS.APPROVED && (
				<BrandButton
					className="btn-brand-xl w-100"
					onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.UNVERIFIED })}
				>
					На проверку
				</BrandButton>
			)}
			{currentOrder?.state?.code === ORDER_STATUS.UNVERIFIED && (
				<div className={classes.buttonWrapper}>
					<BrandButton
						className="btn-brand-outline w-50"
						onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.UNCOMPLETED })}
					>
						Выполнен частично
					</BrandButton>
					<BrandButton
						className="btn-brand-xl w-50"
						onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.COMPLETED })}
					>
						Выполнен
					</BrandButton>
				</div>
			)}
			{currentOrder?.state?.code === ORDER_STATUS.EXPIRED && (
				<BrandButton
					className="btn-brand-xl w-100"
					onClick={() => changeOrderStatus({ id, status: ORDER_STATUS.UNVERIFIED })}
				>
					На проверку
				</BrandButton>
			)}
		</MobileBottomBar>
	);
};

export default OrderItemMobileBottomBar;
