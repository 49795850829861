import React, { useMemo } from "react";
import InfoBlock from "@components/ui/InfoBlock/InfoBlock";
import classes from "./OrderSum.module.css";
import useOrderStore from "@store/OrderStore";

const OrderSum = ({ activeTasks }) => {
	const { calculateSum } = useOrderStore();

	const sum = useMemo(() => {
		return calculateSum(activeTasks);
	}, [activeTasks]);

	return (
		<InfoBlock>
			<p className="text-sm-medium">Сумма</p>
			<h2 className={classes.sum}>{sum} ₽</h2>
		</InfoBlock>
	);
};

export default OrderSum;
