import React from "react";
import { Link } from "react-router-dom";
import classes from "./BrandLink.module.css";

const BrandLink = ({ children, className, ...props }) => {
	return (
		<Link className={`${classes.brandLink} ${className || ""}`} {...props}>
			{children}
		</Link>
	);
};

export default BrandLink;
