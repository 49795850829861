import React from "react";
import { Form } from "react-bootstrap";
import classes from "./BrandSwitch.module.css";

const BrandSwitch = ({ checked, onChange, label, name, className }) => {
	const handleChange = (e) => {
		onChange(e.target.checked);
	};

	return (
		<div className={`${className || ""} text-sm-medium`}>
			<Form.Check
				type="switch"
				id={name || "custom-switch"}
				name={name}
				label={label}
				checked={checked}
				onChange={handleChange}
				className={classes.switch}
			/>
		</div>
	);
};

export default BrandSwitch;
