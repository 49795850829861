import { create } from "zustand";

const useLoaderStore = create((set, get) => ({
	isAppLoading: null,
	isAppBlick: false,
	setIsLoading: (value) => {
		set({ isAppLoading: value });
	},
	setIsBlick: (value) => {
		set({ isAppBlick: value });
	},
}));

export default useLoaderStore;
