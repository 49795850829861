export const THEME_LIST = [
	{
		id: 1,
		title: "Лиловая",
		code: "purple",
		color: "#E7E1F2",
	},
	{
		id: 2,
		title: "Бирюзовая",
		code: "turquoise",
		color: "#97EEE8",
	},
];

export const DEFAULT_THEME = THEME_LIST[0];
