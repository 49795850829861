import { ReactComponent as Pencil } from "@assets/icons/button/pencil.svg";
import OrderSum from "@components/Forms/CreateOrderForm/OrderSum/OrderSum";
import InfoBlock from "@components/ui/InfoBlock/InfoBlock";
import TaskCheckContainer from "@components/TaskCheckContainer/TaskCheckContainer";
import BrandAccordion from "@components/ui/Accordion/BrandAccordion";
import BrandButton from "@components/ui/Button/BrandButton";
import List from "@components/ui/List/List";
import { ListItem } from "@components/ui/ListItem/ListItem";
import { ORDER_COLORS } from "@const/colors";
import { ORDER_STATUS } from "@const/order";
import { TASK_TYPE } from "@const/tasks";
import { convertDatetime, convertISO8601Duration } from "@helpers/day";
import useRouter from "@hooks/useRouter";
import useOrderStore from "@store/OrderStore";
import useTaskStore from "@store/TaskStore";
import React, { useEffect } from "react";
import OrderItemMobileBottomBar from "./mobileBottomBar/OrderItemMobileBottomBar";
import classes from "./OrderItem.module.css";

const OrderItem = () => {
	const { params } = useRouter();
	const { id } = params;
	const { currentOrder, getOrderInfo } = useOrderStore();
	const { setTaskListForCheck } = useTaskStore();

	useEffect(() => {
		if (!id) return;
		getOrderInfo(id);
	}, [id]);

	useEffect(() => {
		return () => setTaskListForCheck([]);
	}, []);

	const ensureHttps = (url) => {
		if (!/^https?:\/\//i.test(url)) {
			return `https://${url}`;
		}
		return url;
	};

	const TaskItem = ({ title, quantity, quantity_completed, labelClass }) => (
		<div className="col-3">
			<p className={`text-sm-medium ${labelClass}`}>{title}</p>
			<p className="text-md-medium">
				{currentOrder.started_on &&
				currentOrder.state.code !== ORDER_STATUS.PENDING &&
				Number.isInteger(quantity_completed)
					? `${quantity_completed} / ${quantity}`
					: `${quantity}`}
			</p>
		</div>
	);

	const commentList = [
		"Ты великолепен!",
		"Продолжай в том же духе!",
		"Так горжусь твоими успехами!",
	];

	const comments = [
		{
			title: "Описание комментов",
			body: (
				<List type="ordered" className="py-0">
					{commentList.map((el) => (
						<ListItem key={el} value={el} className={`text-md-regular ${classes.listItem}`} />
					))}
				</List>
			),
		},
	];

	const secureUrl = ensureHttps(currentOrder?.url);
	return (
		<div className={classes.pageContainer}>
			{currentOrder && (
				<div className={classes.orderInfo}>
					<div className={classes.header}>
						<p className="text-sm-medium" style={{ color: ORDER_COLORS[currentOrder.state.code] }}>
							{currentOrder.state.title}
						</p>
						<div className="d-flex justify-content-between align-items-center">
							<h1>
								Заказ <span className={classes.label}>№{currentOrder.number}</span>
							</h1>
							{currentOrder.state.code === ORDER_STATUS.DRAFT && (
								<BrandButton className="btn-brand-icon" to={`/orders/edit/${id}`}>
									<Pencil />
								</BrandButton>
							)}
						</div>
					</div>
					<OrderSum activeTasks={currentOrder.order_activities} />
					<InfoBlock>
						<div>
							<p className={`text-sm-medium ${classes.label}`}>Заказчик</p>
							<a
								href={`https://${currentOrder.social_network.code}.com/${currentOrder.customer.name}`}
								target="_blank"
								rel="noreferrer"
							>
								{currentOrder.customer.name}
							</a>
						</div>
						{currentOrder.started_on && (
							<div>
								<p className={`text-sm-medium ${classes.label}`}>Дата старта</p>
								<p className="text-sm-medium">{convertDatetime(currentOrder.started_on)}</p>
							</div>
						)}
						{currentOrder.finished_on ? (
							<div>
								<p className={`text-sm-medium ${classes.label}`}>Срок выполнения</p>
								<p className="text-sm-medium">до {convertDatetime(currentOrder.finished_on)}</p>
							</div>
						) : (
							currentOrder.completion_time && (
								<div>
									<p className={`text-sm-medium ${classes.label}`}>Срок выполнения</p>
									<p className="text-sm-medium">
										{convertISO8601Duration(currentOrder.completion_time)} после запуска
									</p>
								</div>
							)
						)}
						<div>
							<p className={`text-sm-medium ${classes.label}`}>Описание</p>
							<p>{currentOrder.comment}</p>
						</div>
						<BrandButton to={secureUrl} target="_blank" className="btn-brand-non-outline">
							Ссылка на пост
						</BrandButton>
					</InfoBlock>
					<InfoBlock>
						{currentOrder.started_on ? (
							<p className="text-sm-medium">Заданий принято</p>
						) : (
							<p className="text-sm-medium">Задания</p>
						)}

						<div className={classes.taskWrapper}>
							{currentOrder.order_activities?.length > 0 &&
								currentOrder.order_activities.map((task) => {
									const { activity, additional_completion_time } = task.social_network_activity;
									const title = activity.title;
									const quantity = task.quantity;
									const quantity_completed = task.quantity_completed;

									if (activity.code === TASK_TYPE.VIEW_LIVE) {
										return (
											<React.Fragment key={task.id}>
												<TaskItem
													title="Продолжительность эфира"
													quantity={convertISO8601Duration(additional_completion_time)}
													labelClass={classes.label}
													quantity_completed={null}
												/>
												<TaskItem
													title={title}
													quantity={quantity}
													quantity_completed={quantity_completed}
													labelClass={classes.label}
												/>
											</React.Fragment>
										);
									}

									return (
										<TaskItem
											key={task.id}
											title={title}
											quantity={quantity}
											quantity_completed={quantity_completed}
											labelClass={classes.label}
										/>
									);
								})}
						</div>
						<BrandAccordion content={comments} />
					</InfoBlock>
					<OrderItemMobileBottomBar />
				</div>
			)}
			<TaskCheckContainer orderId={id} />
		</div>
	);
};

export default OrderItem;
