import React from "react";
import TaskCard from "../TaskCard/TaskCard";
import classes from "./TaskList.module.css";

const TaskList = ({ tasks, loadTasks, clearTasks, emptyList = "Список заданий пуст" }) => {
	return (
		<>
			<div className={classes.wrapper}>
				{tasks?.length > 0 ? (
					tasks.map((task) => {
						return (
							<TaskCard key={task.id} task={task} loadTasks={loadTasks} clearTasks={clearTasks} />
						);
					})
				) : (
					<p className="text-md-medium">{emptyList}</p>
				)}
			</div>
		</>
	);
};

export default TaskList;
