import useModalStore from "@store/ModalStore";
import useUserStore from "@store/UserStore";
import BrandButton from "@ui/Button/BrandButton";
import BrandInput from "@ui/Input/BrandInput";
import PageTitle from "@ui/PageTitle/PageTitle";
import React, { useState } from "react";
import classes from "./InviteContractor.module.css";
import useLoaderStore from "@store/LoaderStore";
import AppLoader from "@components/ui/Loader/AppLoader/AppLoader";
const InviteContractor = () => {
	const { hideModal } = useModalStore();
	const { inviteContractor } = useUserStore();
	const { isAppLoading } = useLoaderStore();
	const [contractorData, setContractorData] = useState({});
	const [error, setError] = useState({});
	const [requestError, setRequestError] = useState("");
	const changeContractorDataHandler = (value, field) => {
		setContractorData((prev) => ({ ...prev, [field]: value }));
	};

	const validateFields = () => {
		let isValid = true;
		const newErrors = {};

		const nameRegex = /^[^\d]+$/;

		if (!contractorData.name || contractorData.name.trim().length < 2) {
			newErrors.name = "Имя должно быть не менее 2 символов";
			isValid = false;
		} else if (!nameRegex.test(contractorData.name)) {
			newErrors.name = "Имя не должно содержать цифр";
			isValid = false;
		}

		if (!contractorData.surname || contractorData.surname.trim().length < 2) {
			newErrors.surname = "Фамилия должна быть не менее 2 символов";
			isValid = false;
		} else if (!nameRegex.test(contractorData.surname)) {
			newErrors.surname = "Фамилия не должна содержать цифр";
			isValid = false;
		}

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!contractorData.email || !emailRegex.test(contractorData.email)) {
			newErrors.email = "Введите корректный email";
			isValid = false;
		}

		setError(newErrors);
		return isValid;
	};

	const inviteContractorHandler = () => {
		setError({});
		const isValid = validateFields();
		if (isValid) {
			inviteContractor({ contractorData, setRequestError, hideModal });
		}
	};

	return (
		<section className="d-flex flex-column gap-3" style={{ width: "350px" }}>
			{isAppLoading && <AppLoader />}
			<PageTitle title="Добавить исполнителя" />
			<BrandInput
				placeholder="Введите имя"
				label="имя"
				value={contractorData.name}
				onChange={(value) => changeContractorDataHandler(value, "name")}
				valid={!error.name}
				error={error.name}
			/>
			<BrandInput
				placeholder="Введите фамилия"
				label="фамилия"
				value={contractorData.surname}
				onChange={(value) => changeContractorDataHandler(value, "surname")}
				valid={!error.surname}
				error={error.surname}
			/>
			<BrandInput
				placeholder="Email исполнителя"
				label="почта"
				value={contractorData.email}
				onChange={(value) => changeContractorDataHandler(value, "email")}
				valid={!error.email}
				error={error.email}
			/>
			<BrandButton
				className="btn-brand-xl w-100"
				onClick={inviteContractorHandler}
				disabled={isAppLoading}
			>
				Добавить
			</BrandButton>
			{requestError && <p className={classes.error}>{requestError}</p>}
		</section>
	);
};

export default InviteContractor;
