import { socket } from "@/socket";
import { DEFAULT_ROLE, USER_ROLE } from "@const/roles";
import { TOKEN } from "@const/token";
import Cookies from "js-cookie";
import { create } from "zustand";
import useModuleStore from "./ModuleStore";
import useRequestStore from "./RequestStore";
import useProfileStore from "./ProfileStore";

const useAuthStore = create((set, get) => ({
	isWebSocketConnected: socket.connected,
	isAuth: false,
	accessToken: null,
	refreshToken: null,
	profileData: null,
	userRoles: null,
	userRole: DEFAULT_ROLE,

	setTokens: (access_token, refresh_token, withToken) => {
		Cookies.set(TOKEN.BRAND_ACCESS_TOKEN, access_token.token, {
			expires: new Date(access_token.exp),
			secure: true,
		});
		if (!withToken) {
			Cookies.set(TOKEN.BRAND_REFRESH_TOKEN, refresh_token.token, {
				expires: new Date(refresh_token.exp),
				secure: true,
			});
		}
		set({
			accessToken: access_token,
			refreshToken: refresh_token,
		});
	},
	removeTokens: () => {
		set({
			accessToken: null,
			refreshToken: null,
		});
		Cookies.remove(TOKEN.BRAND_ACCESS_TOKEN);
		Cookies.remove(TOKEN.BRAND_REFRESH_TOKEN);
	},
	handleRedirect: (navigate, role) => {
		if (navigate) {
			role === USER_ROLE.CONTRACTOR ? navigate("/tasks") : navigate("/orders");
		}
	},
	signUpConfirm: (token) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("users:sign_up_confirm", {
			requestData: {
				registration_request_id: token,
			},
			successCb: (responseData) => {
				console.log("Подтверждение регистрации пользователя успешно");
			},
		});
	},
	signUpComplete: (userData, handleFormError, successCb) => {
		const { password, token, phone } = userData;
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("users:sign_up_complete", {
			requestData: {
				registration_request_id: token,
				phone: phone,
				password,
				is_confirmed_processing_personal_data: true,
				is_confirmed_privacy_policy: true,
				is_confirmed_public_offer_agreement: true,
			},
			successCb: (responseData) => {
				successCb();
			},
			errorCb: (error) => {
				handleFormError && handleFormError(error.title);
			},
		});
	},
	login: (navigate, userData, withToken = false, handleFormError) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		const event = withToken ? "users:sign_in_token" : "users:sign_in";
		handleRequest(event, {
			requestData: withToken ? { token: userData } : userData,
			successCb: (responseData) => {
				const { user, access_token, refresh_token } = responseData;
				if (!user.confirmed_on) {
					get().logout();
					return;
				}
				set({ profileData: user });
				set({ isAuth: true });

				// let activeRole = Cookies.get(BRAND_ACTIVE_ROLE) || user.roles[0]?.role?.code;
				let activeRole = user.roles[0]?.role?.code;
				if (!activeRole) {
					alert("У пользователя не добавлено ни одной роли");
					get().logout();
					return;
				}
				get().setUserRoles(user.roles);
				get().setActiveRole({ role: activeRole });
				get().setTokens(access_token, refresh_token, withToken);
				const { moduleList, getModuleList } = useModuleStore.getState();

				if (!moduleList?.length) getModuleList({ page: 1, limit: 100 });
				get().handleRedirect(navigate, activeRole);
			},
			errorCb: (error) => {
				handleFormError && handleFormError(error.title);
			},
		});
	},
	logout: () => {
		const handleRequest = useRequestStore.getState().handleRequest;

		get().removeTokens();
		set({ profileData: null });
		set({ isAuth: false });
		get().setUserRoles(null);
		handleRequest("users:sign_out");
		window.location.href = "/signin";
	},
	setActiveRole: ({ role, successCb, navigate }) => {
		set({ userRole: role });
		// Cookies.set(BRAND_ACTIVE_ROLE, role, {
		// 	expires: 7,
		// 	secure: true,
		// });
		const handleRequest = useRequestStore.getState().handleRequest;
		const getProfileInfo = useProfileStore.getState().getProfileInfo;
		handleRequest("users:set_role", {
			requestData: { role_code: role },
			successCb: () => {
				successCb && successCb();
				console.log(`Роль установлена ${role}`);
				getProfileInfo();
				get().handleRedirect(navigate, role);
			},
			errorCb: (error) => {
				console.error("Не удалось установить роль:", error);
			},
		});
	},
	changeRoles: ({ user_id, roles, successCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("users:change_roles", {
			requestData: { entity_id: user_id, role_codes: roles },
			successCb: (responseData) => {
				console.log(`Роли установлены ${JSON.stringify(responseData.item)}`);
				successCb && successCb();
			},
			errorCb: (error) => {
				console.error("Не удалось изменить роли:", error);
			},
		});
	},

	setUserRoles: (roles) => {
		set({ userRoles: roles });
	},

	setIsWebSocketConnected: (value) => {
		set({ isWebSocketConnected: value });
	},
	isOperationalDirector: () => {
		return get().userRole === USER_ROLE.OPERATIONAL_DIRECTOR;
	},
}));

export default useAuthStore;
