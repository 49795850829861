import React, { useState, useEffect, forwardRef } from "react";
import BrandSelect from "@ui/Select/BrandSelect";
import BrandTextArea from "@ui/TextArea/BrandTextArea";
import Counter from "@components/Forms/CreateOrderForm/Counter/Counter";
import useOrderStore from "@store/OrderStore";
import { convertISO8601Duration } from "@helpers/day";
import classes from "./TaskBlock.module.css";
import { TASK_ACTIVITY_ID } from "../../../../../const/order";

const TaskBlock = forwardRef(({ title, onValueChange, quantity }, ref) => {
	const {
		orderData,
		updateOrderData,
		socialNetworkActivitiesList,
		activeTasks,
		setActiveTasks,
		getTaskId,
		getTaskActivityId,
	} = useOrderStore();

	const [selectValue, setSelectValue] = useState("");

	const liveBroadCastActivities = socialNetworkActivitiesList?.filter(
		(el) => el.activity.title === "Просмотр прямого эфира"
	);

	const formatOptionsForSelect = () => {
		return liveBroadCastActivities?.map((el) => ({
			value: el.id,
			label: convertISO8601Duration(el.additional_completion_time),
		}));
	};

	useEffect(() => {
		const task = activeTasks.find((task) => getTaskActivityId(task) === TASK_ACTIVITY_ID.VIEW_LIVE);
		if (task) {
			const taskId = getTaskId(task);
			setSelectValue(taskId);
		} else {
			setSelectValue(null);
		}
	}, [activeTasks]);

	const onLiveViewTypeChange = (value) => {
		setSelectValue(value);
		const newTaskList = activeTasks.map((task) => {
			if (getTaskActivityId(task) === TASK_ACTIVITY_ID.VIEW_LIVE) {
				const selectedActivity = liveBroadCastActivities.find((e) => e.id === +value);
				if (selectedActivity) {
					return { ...selectedActivity };
				}
			}
			return task;
		});
		setActiveTasks(newTaskList);
	};

	return (
		<div className={classes.taskBlock} ref={ref}>
			<div className={classes.taskWrapper}>
				<p className={`${classes.title} text-sm-medium`}>{title}</p>

				<Counter onValueChange={onValueChange} min={1} step={1} value={quantity || 1} />
			</div>

			{title === "Комментарий" && (
				<div className="row">
					<div className="col-md-6 col-12">
						<BrandTextArea
							placeholder="Что нужно учесть при написании комментариев?"
							value={orderData.comments}
							onChange={(v) => updateOrderData("comments", v)}
						/>
					</div>
				</div>
			)}

			{title === "Просмотр прямого эфира" && (
				<div className="row">
					<div className="col-md-6 col-12">
						<BrandSelect
							options={formatOptionsForSelect()}
							value={selectValue}
							onChange={onLiveViewTypeChange}
							placeholder="Продолжительность Эфира"
						/>
					</div>
				</div>
			)}
		</div>
	);
});

export default TaskBlock;
