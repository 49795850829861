import BrandButton from "@components/ui/Button/BrandButton";
import InfoBlock from "@components/ui/InfoBlock/InfoBlock";
import PageTitle from "@components/ui/PageTitle/PageTitle";
import { THEME_LIST } from "@const/themes";
import { formatDateLongMonth } from "@helpers/day";
import useThemeStore from "@store/ThemeStore";
import React from "react";
import classes from "./ProfileMainInfo.module.css";
import useRouter from "@hooks/useRouter";

const ProfileMainInfo = ({ profileInfo }) => {
	const { getTheme, changeTheme } = useThemeStore();
	const { navigate } = useRouter();
	if (!profileInfo?.name) {
		return <p>Информация отсутствует</p>;
	}

	const hasSocial = (social_code) =>
		profileInfo?.social_network_accounts.find(
			(social) => social.social_network.code === social_code
		);

	const fullBankInfo = () => {
		for (let key in profileInfo.bank_account) {
			if (!profileInfo.bank_account[key]) {
				return false;
			}
		}
		return true;
	};

	return (
		<div className={classes.wrapper}>
			<InfoBlock>
				<p className={classes.title}>Баланс</p>
				<PageTitle title={profileInfo.wallet?.balance} />
			</InfoBlock>
			<InfoBlock>
				<p className={classes.title}>Общая информация</p>
				{profileInfo.email && (
					<div>
						<p className={classes.subtitle}>Почта</p>
						<p className={classes.paragraph}>{profileInfo.email}</p>
					</div>
				)}
				{profileInfo.phone && (
					<div>
						<p className={classes.subtitle}>Телефон</p>
						<p className={classes.paragraph}>{profileInfo.phone}</p>
					</div>
				)}
				<div>
					<p className={classes.subtitle}>Дата регистрации</p>
					<p className={classes.paragraph}>{formatDateLongMonth(profileInfo.confirmed_on)}</p>
				</div>
			</InfoBlock>

			<InfoBlock>
				<p className={classes.title}>Соцсети</p>
				<div>
					<p className={classes.subtitle}>Инстаграм</p>
					<p className={classes.paragraph}>
						{hasSocial("instagram")?.account_id ? (
							`@${hasSocial("instagram").account_id}`
						) : (
							<BrandButton
								className="btn-brand-non-outline"
								onClick={() => navigate("/profile/edit")}
							>
								Добавить инстаграм
							</BrandButton>
						)}
					</p>
				</div>
				<div>
					<p className={classes.subtitle}>Телеграм</p>
					<p className={classes.paragraph}>
						{hasSocial("telegram")?.account_id ? (
							`@${hasSocial("telegram").account_id}`
						) : (
							<BrandButton
								className="btn-brand-non-outline"
								onClick={() => navigate("/profile/edit")}
							>
								Добавить телеграм
							</BrandButton>
						)}
					</p>
				</div>
			</InfoBlock>
			{(profileInfo.order_activity_completed || profileInfo.order_activity_created) && (
				<InfoBlock>
					<p className={classes.title}>Задания</p>
					<div className={classes.container}>
						{profileInfo.order_activity_completed && (
							<div>
								<p className={classes.subtitle}>Принято</p>
								<p className={classes.paragraph}>{profileInfo.order_activity_completed}</p>
							</div>
						)}
						{profileInfo.order_activity_created && (
							<div>
								<p className={classes.subtitle}>Выполнено</p>
								<p className={classes.paragraph}>{profileInfo.order_activity_created}</p>
							</div>
						)}
					</div>
				</InfoBlock>
			)}
			<InfoBlock>
				<p className={classes.subtitle}>Реквизиты</p>
				{fullBankInfo() ? (
					<div>
						<p className={classes.subtitle}>Банк и номер счета</p>
						<p className={classes.paragraph}>
							{profileInfo.bank_account.bank_title} {profileInfo.bank_account.account_mask}
						</p>
					</div>
				) : (
					<div className={classes.container}>
						<BrandButton
							className={`btn-brand-non-outline`}
							onClick={() => navigate("/profile/edit")}
						>
							Добавить
						</BrandButton>
						<p className={classes.paragraph}>Без реквизитов получить выплату не получится</p>
					</div>
				)}
			</InfoBlock>
			<InfoBlock>
				<p className={classes.subtitle}>Транзакции</p>
				{!profileInfo.transactionList && (
					<p className={classes.paragraph}>Пока никаких переводов и пополнений не было</p>
				)}
			</InfoBlock>
			<InfoBlock>
				<p className={classes.subtitle}>Тема</p>
				<div className={classes.container}>
					{THEME_LIST.map((theme) => {
						const isActiveTheme = (code) => code === getTheme();
						return (
							<div
								className={classes.themeWrapper}
								key={theme.id}
								onClick={() => changeTheme(theme.code)}
							>
								<p
									className={`${classes.theme} ${isActiveTheme(theme.code) ? classes.active : ""}`}
									style={{ background: theme.color }}
								/>
								<p
									className={`text-sm-medium ${classes.themeTitle} ${
										isActiveTheme(theme.code) ? classes.active : ""
									}`}
								>
									{theme.title}
								</p>
							</div>
						);
					})}
				</div>
			</InfoBlock>
			<BrandButton className={`btn-brand-non-outline ${classes.deleteAccountBtn}`}>
				Удалить профиль
			</BrandButton>
		</div>
	);
};

export default ProfileMainInfo;
