// BrandModal.js
import React from "react";
import { Modal } from "react-bootstrap";
import useModalStore from "@store/ModalStore";
import classes from "./BrandModal.module.css";

const BrandModal = () => {
	const { modalMessage, isShowModal, hideModal, modalClassName } = useModalStore();

	return (
		<Modal
			show={isShowModal}
			onHide={hideModal}
			className={`${classes.brandModal} ${modalClassName || ""}`}
		>
			<Modal.Body>{modalMessage}</Modal.Body>
		</Modal>
	);
};

export default BrandModal;
