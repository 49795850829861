import BurgerMenu from "@components/BurgerMenu/BurgerMenu";
import { ROLE_MAIN_PAGE } from "@const/headerConfig";
import useRouter from "@hooks/useRouter";
import CreateOrderPageHeader from "@pages/OrdersPage/create/header/CreateOrderPageHeader";
import OrderItemHeader from "@pages/OrdersPage/OrderItem/header/OrderItemHeader";
import OrdersPageHeader from "@pages/OrdersPage/OrderList/header/OrdersPageHeader";
import TaskPageHeader from "@pages/TasksPage/header/TaskPageHeader";
import useAuthStore from "@store/AuthStore";
import TaskHistoryPageHeader from "@pages/TasksHistoryPage/header/TaskHistoryPageHeader";
import useHeaderStore from "@store/HeaderStore";
import useMobileMenuStore from "@store/MobileMenuStore";
import BackButton from "@ui/BackButton/BackButton";
import BrandLink from "@ui/Link/BrandLink";
import Logo from "@ui/Logo/Logo";
import React, { useEffect } from "react";
import { matchPath } from "react-router-dom";
import classes from "./Header.module.css";
import ContractorPageHeader from "@pages/ContractorsPage/ContractorPageHeader/ContractorPageHeader";
import ProfilePageHeader from "@pages/ProfilePage/header/ProfilePageHeader";
import ProfilePageEditHeader from "@pages/ProfilePage/edit/header/ProfilePageEditHeader";

const HeaderLayout = () => {
	const { location } = useRouter();
	const { setHeaderFixed, isHeaderFixed } = useHeaderStore();
	const { setIsMobileMenuShow } = useMobileMenuStore();
	const { userRole } = useAuthStore();
	const routes = [
		{
			path: "/orders/create",
			component: <CreateOrderPageHeader />,
			showLogoMobile: false,
		},
		{ path: "/orders", component: <OrdersPageHeader />, showLogoMobile: true },
		{ path: "/orders/:id", component: <OrderItemHeader />, showLogoMobile: false },
		{ path: "/orders/edit/:id", component: <CreateOrderPageHeader />, showLogoMobile: false },
		{ path: "/tasks", component: <TaskPageHeader />, showLogoMobile: false, balance: true },
		{
			path: "/tasks_history",
			component: <TaskHistoryPageHeader />,
			showLogoMobile: true,
		},
		{
			path: "/contractors",
			component: <ContractorPageHeader />,
			showLogoMobile: true,
		},
		{
			path: "/profile",
			component: <ProfilePageHeader />,
			showLogoMobile: false,
		},
		{
			path: "/profile/edit",
			component: <ProfilePageEditHeader />,
			showLogoMobile: false,
		},
	];

	const getCurrentRoute = () => {
		for (const route of routes) {
			if (matchPath(route.path, location.pathname)) {
				return route;
			}
		}
		return null;
	};

	const currentRoute = getCurrentRoute();

	useEffect(() => {
		const handleScroll = () => {
			window.scrollY > 0 ? setHeaderFixed(true) : setHeaderFixed(false);
		};

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [setHeaderFixed]);

	return (
		<div className={`${classes.headerWrapper} ${isHeaderFixed ? classes.fixed : ""}`}>
			<div className={classes.headerTop}>
				<BrandLink to={ROLE_MAIN_PAGE[userRole]} className={`${classes.logo} mobile-hide`}>
					<Logo />
				</BrandLink>
				{currentRoute?.showLogoMobile ? (
					<BrandLink to="orders" className={`${classes.logo} desktop-hide`}>
						<Logo />
					</BrandLink>
				) : currentRoute?.balance ? (
					""
				) : (
					<BackButton className="desktop-hide" />
				)}
				<div className={classes.headerContent}>{currentRoute?.component}</div>
				<BurgerMenu className={`${classes.burgerMenu}`} onClick={() => setIsMobileMenuShow(true)} />
			</div>
		</div>
	);
};

export default HeaderLayout;
