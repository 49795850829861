import { create } from "zustand";
import useRequestStore from "./RequestStore";
import useAuthStore from "./AuthStore";

const useUserStore = create((set, get) => ({
	userList: [],
	setUserList: (userList) => set({ userList }),
	createCustomer: ({ customerNick, hideModal }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("customers:create", {
			requestData: { name: customerNick },
			successCb: (responseData) => {
				console.log("Заказчик успешно создан:", responseData);
				hideModal();
			},
			errorCb: (error) => {
				console.error("Ошибка создания заказчика:", error);
			},
		});
	},
	inviteContractor: ({ contractorData, setRequestError, hideModal }) => {
		const { name, surname, email } = contractorData;
		const handleRequest = useRequestStore.getState().handleRequest;
		const changeRoles = useAuthStore.getState().changeRoles;
		handleRequest("users:create", {
			requestData: { name, surname, email },
			successCb: (responseData) => {
				const userId = responseData.item.id;
				changeRoles({ user_id: userId, roles: ["contractor"], successCb: () => hideModal() });
				console.error("Приглашение успешно отправлено", responseData);
			},
			errorCb: (error) => {
				console.error("Ошибка отправки приглашения:", error);
				setRequestError(error.title);
			},
		});
	},
	getUserList: ({ page, limit, filters, successCb }) => {
		const handleRequest = useRequestStore.getState().handleRequest;
		handleRequest("users:list", {
			requestData: { page, limit, filters },
			successCb: (responseData) => {
				set({ userList: responseData.items });
				console.log("Список пользователей", responseData);
				successCb && successCb(responseData);
			},
			errorCb: (error) => {
				console.error("Ошибка отправки приглашения:", error);
			},
		});
	},
}));

export default useUserStore;
