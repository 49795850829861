import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "./css/reset.css";
import "./css/buttons.css";
import "./css/index.css";
import "./css/inputs.css";
import "./css/tables.css";
import "./css/modal.css";
import { router } from "./routes/routes";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<RouterProvider router={router} />);

serviceWorkerRegistration.register();
