import MobileBottomBar from "@components/MobileBottomBar/MobileBottomBar";
import React from "react";
import KebabMenu from "@components/ui/KebabMenu/KebabMenu";
import BrandButton from "@components/ui/Button/BrandButton";
import useRouter from "@hooks/useRouter";
import useAuthStore from "@store/AuthStore";
const ProfilePageMobileBottomBar = () => {
	const { navigate } = useRouter();
	const { logout } = useAuthStore();
	return (
		<MobileBottomBar>
			<KebabMenu
				mainButton={
					<BrandButton className="btn-brand-xl" onClick={() => navigate("/profile/edit")}>
						Редактировать
					</BrandButton>
				}
				dropdownButtons={
					<>
						<BrandButton
							className="btn-brand-outline w-100"
							// onClick={() => navigate("/profile/edit")}
						>
							Сбросить пароль
						</BrandButton>
						<BrandButton className="btn-brand-outline w-100" onClick={logout}>
							Выйти
						</BrandButton>
					</>
				}
			/>
		</MobileBottomBar>
	);
};

export default ProfilePageMobileBottomBar;
