import HeaderLayout from "@components/Header/Header";
import Sidebar from "@components/Sidebar/Sidebar";
import { DEFAULT_THEME } from "@const/themes";
import useLoaderStore from "@store/LoaderStore";
import useMobileMenuStore from "@store/MobileMenuStore";
import useThemeStore from "@store/ThemeStore";
import AppLoader from "@ui/Loader/AppLoader/AppLoader";
import BrandModal from "@ui/Modal/BrandModal";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import classes from "../App/AppContainer.module.css";
import MobileMenu from "../MobileMenu/MobileMenu";

const AppLayout = () => {
	const { isAppLoading, isAppBlick } = useLoaderStore();
	const { isMobileMenuShow } = useMobileMenuStore();
	const { changeTheme, getTheme } = useThemeStore();
	const isStartPage =
		window.location.pathname === "/signin" || window.location.pathname === "/complete_registration";

	useEffect(() => {
		const theme = getTheme();
		theme ? changeTheme(theme) : changeTheme(DEFAULT_THEME.code);
	}, []);

	return (
		<div
			className={`
				${classes.appWrapper} 
				${isStartPage ? "container justify-content-center" : ""}
				${isAppBlick ? classes.appWrapperHidden : classes.appWrapperVisible}
			 	position-relative `}
			data-theme="violet"
		>
			{isAppLoading && <AppLoader />}
			{isMobileMenuShow && <MobileMenu />}

			<BrandModal />

			{!isStartPage && <HeaderLayout />}

			<div
				style={{ overflow: isMobileMenuShow ? "hidden" : "unset" }}
				className={`d-flex h-100 ${isStartPage ? "justify-content-center" : ""}`}
			>
				{!isStartPage && <Sidebar />}

				<main
					className={`w-100 z-1 ${classes.content} ${isStartPage ? classes.contentStartPage : ""}`}
				>
					<Outlet />
				</main>
			</div>
		</div>
	);
};

export default AppLayout;
