import { create } from "zustand";

const useModalStore = create((set, get) => ({
	isShowModal: false,
	modalMessage: null,
	modalClassName: null,

	setModalMessage: (content) => {
		set({ modalMessage: content });
	},

	showModal: (content, customClass = "") => {
		set({
			isShowModal: true,
			modalClassName: customClass,
		});
		get().setModalMessage(content);
	},

	hideModal: () => {
		set({
			isShowModal: false,
			modalClassName: null,
		});
		get().setModalMessage(null);
	},
}));

export default useModalStore;
